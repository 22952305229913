import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import image from "../../../components/images/loading.gif";
import axiosInstance from "../../../utils/axios";
import FormFileUploadComponent from './FileUploadModal';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10, 20, 15),
  },
}));

export default function FileUploadModal({ open=false, data, control, typeOfTheDividend,closeModal}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [excelFileError, setExcelFileError] = useState(false);
  const [excelFile, setExcelFile] = useState([]);
  const [dividendType, setDividendType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(e);
  
    const formData = new FormData();
    formData.append('excelFiles', excelFile);
    formData.append('inwarddividendType', dividendType);
    formData.append('issuerid', data.issuerid);
    formData.append('inwarddividendid', data.id);

    try {
      const response = await axiosInstance.post('investor-informations', formData);
  
      setIsLoading(false);
      setIsSuccess(true);
      setExcelFileError(false);
  
      if (response.data.payload.noofentry ===0) {
        setIsSuccess(false);
        setExcelFileError(true);
        
      }

      // if (response.data.payload.statusCode) {
      //   setIsSuccess(true); 
      // }
      
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }


  }
console.log(isSuccess)
const cancelButtonHandler = (e) => {
  setExcelFile([]);
  setDividendType("");
  setIsSuccess(false);
  closeModal();
  
};
  
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={control}
        onClose={(event, reason) => {
          if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              closeModal();
          }
      }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableEscapeKeyDown={true}
        // disableBackdropClick
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
           
          <div className={classes.paper}>
          {isSuccess && <h3 style={{color:"green"}}>Successfully imported data from excel file!</h3>}
          {excelFileError && <h3 style={{color:"orange"}}>Your Excel file contains no Data. Please check it again!</h3>}
          {isLoading && <span style={{color:"blue"}}><img width="380px" src={image} alt="Loading..." /></span>}
          {isError && <p style={{color:"red"}}>There was an error occured!</p>}
            <h2 id="transition-modal-title">Inward Dividend File</h2>
            <p style={{color:'red'}} id="transition-modal-description">Please Make Sure that you upload the final version of the Investor Details.  It must not contain repeatative data. <br/>Max File Size: 10 MB</p>
            <form onSubmit={handleSubmit}>
            {typeOfTheDividend === 'All'?
                <><FormControl fullWidth >
                <InputLabel id="issuer-id" >Select Dividend Type</InputLabel>
                <Select 
                required
                    labelId="issuer-id"
                    id="demo-simple-select"
                    value={dividendType}
                    label="Select Issuer"
                    onChange={(e) => setDividendType(e.target.value)}
                    >
                    <MenuItem value="CashDividend_9_1" >Cash</MenuItem>
                    <MenuItem value="PSM_9_4" >Public Subscription Money</MenuItem>
                    <MenuItem value="FC_Dividend_9_1" >FC Cash Dividend</MenuItem>
                    <MenuItem value="FC_PSM_9_4" >FCPublic Subscription Money</MenuItem>
                    <MenuItem value="BonusShare_9_2" >Stock Bonus Share</MenuItem>
                    <MenuItem value="RightShare_9_3" >Stock Right Share</MenuItem>
                </Select>
                </FormControl></>
              :typeOfTheDividend === 'Cash'?
                 <><FormControl fullWidth >
                 <InputLabel id="issuer-id" >Select Dividend Type</InputLabel>
                 <Select 
                 required
                     labelId="issuer-id"
                     id="demo-simple-select"
                     value={dividendType}
                     label="Select Issuer"
                     onChange={(e) => setDividendType(e.target.value)}
                     >
                     <MenuItem value="CashDividend_9_1" >Cash</MenuItem>
                     <MenuItem value="PSM_9_4" >Public Subscription Money</MenuItem>
                 </Select>
                 </FormControl></>
              :typeOfTheDividend === 'FC'?
                  <><FormControl fullWidth >
                  <InputLabel id="issuer-id" >Select Dividend Type</InputLabel>
                  <Select 
                  required
                      labelId="issuer-id"
                      id="demo-simple-select"
                      value={dividendType}
                      label="Select Issuer"
                      onChange={(e) => setDividendType(e.target.value)}
                      >
                      <MenuItem value="FC_Dividend_9_1" >FC Cash Dividend</MenuItem>
                      <MenuItem value="FC_PSM_9_4" >FCPublic Subscription Money</MenuItem>
                  </Select>
                  </FormControl></>
              :typeOfTheDividend === 'Stock'?
                  <><FormControl fullWidth >
                  <InputLabel id="issuer-id" >Select Dividend Type</InputLabel>
                  <Select 
                  required
                      labelId="issuer-id"
                      id="demo-simple-select"
                      value={dividendType}
                      label="Select Issuer"
                      onChange={(e) => setDividendType(e.target.value)}
                      >
                      <MenuItem value="BonusShare_9_2" >Stock Bonus Share</MenuItem>
                      <MenuItem value="RightShare_9_3" >Stock Right Share</MenuItem>
                  </Select>
                  </FormControl></>
                  :''}
                <h4 style={{marginTop:"10px"}}> </h4>
                
            <TextField
              id="excelFile"
              label="Excel File"
              variant="filled"
              placeholder="Enter Your Excel File"
              fullWidth
              focused
              margin="normal"
              type="file"
              onChange={(e) => setExcelFile( e.target.files[0])}
            />

            <div style={{marginBottom: "10px"}}>
                 {excelFile.length === 0 ? null: excelFile.name}
            </div>
          
                <Button style={{float:"left", margin: "10px"}}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isLoading || isSuccess || excelFile.length === 0}
                   
                  
                  >
                    Add
                  </Button> 
            </form> 
                 <Button style={{margin: "10px"}}
                    variant="contained"
                    color={isSuccess ? 'secondary' : 'primary'}
                    type="submit"
                    size="large"
                    onClick={cancelButtonHandler}
                  >
                    {isSuccess ? 'Close' : 'Cancel'}
                  </Button>
            <div>
              <FormFileUploadComponent  handleClose ={control}/>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
