import { Grid ,Paper} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
  
  // styles
  import useStyles from "./styles";
  
  // components
  import { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import axios from "../../utils/axios";
import Barchart from "./charts/BarchartCash";
import BarchartStock from "./charts/BarchartStock";
import AreaCharts from "./charts/AreaCharts";
import GridItem from "./GridItem";
  
// const userName = getUserName();
// console.log(userName.fullName);
const user = localStorage.getItem('user-name')
const userFormatted = JSON.parse(user);
  // const userType = userFormatted.userTypes[0]
  // console.log(userType);
  
  export default function 
  DashboardInvestors(props) {
  
 
    const [claimData, setClaimData] = useState("")
    const [claimDataCash, setClaimDataCash] = useState("")
    const [claimDataFC, setClaimDataFC] = useState("")
    const [claimDataStock, setClaimDataStock] = useState("")
    const [chartData, setchartData] = useState("")

    useEffect(()=>{
          axios.get(`dashboard/investors/card/${userFormatted?.id}`)
          .then((res) =>{
              if(res.data.success){
                setClaimData(res?.data?.payload)
                console.log(res?.data?.payload);
                setClaimDataCash(res?.data?.payload[0])
                setClaimDataFC(res?.data?.payload[1])
                setClaimDataStock(res?.data?.payload[2])
              }
          })
          .catch((err) =>{
          })
          // dashboard/investors/chart/${userFormatted?.id} -- THIS IS USER WISE API
          axios.get(`dashboard/management/chart/cash`)
          .then((res) =>{
              if(res.data.success){
               console.log(res.data.payload.chart)
               setchartData(res?.data?.payload?.chart)
              }
          })
          .catch((err) =>{
          })
          

    },[]);

// console.log(userFormatted);

  
    const { t } = useTranslation();
    var classes = useStyles();
    var theme = useTheme();
  
  
  
    return (
      <>
        <PageTitle title={t('Dashboard.title3') }  />
        <h3>{t('Project.Welcome')}, {userFormatted?.fullName}</h3>
        <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.Cash')}</h4></Grid>

        {/* <Box > */}

          <Grid style={{marginTop:"15px"}} container spacing={2}>
            {/* {claimData ?
              ( */}
                <>
                <GridItem  name="Lodged Claims" data={claimDataCash?claimDataCash.lodgedClaims : 0} colors="black" url={'/app/claim-settlement/claim/investors/list'} />
                <GridItem name="Settled" data={claimDataCash?claimDataCash.settledClaims : 0}  colors="green" url={'/app/claim-settlement/claim/investors/list'}/>
                <GridItem name="Ongoing" data={claimDataCash?claimDataCash.ongoingClaims : 0}  colors="orange" url={'/app/claim-settlement/claim/investors/list'}/>
                <GridItem name="Declined" data={claimDataCash?claimDataCash.declinedClaims : 0} colors="darkred" url={'/app/claim-settlement/claim/investors/list'}/>
                </>
              {/* )
             :<><h4>No availabale Data</h4></>
            } */}
          </Grid>

          <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.Stock')}</h4></Grid>
            <Grid style={{marginTop:"15px"}} container spacing={4}>  
            {/* {claimData ?
              ( */}
                <>
                  <GridItem name="Lodged Claims" data={claimDataStock? claimDataStock.lodgedClaims : 0}url={'/app/claim-settlement/claim/investors/list'} colors="black"/>
                  <GridItem name="Settled" data={claimDataStock? claimDataStock.settledClaims: 0} url={'/app/claim-settlement/claim/investors/list'} colors="green" />
                  <GridItem name="Ongoing" data={claimDataStock? claimDataStock.ongoingClaims: 0}url={'/app/claim-settlement/claim/investors/list'} colors="orange"/>
                  <GridItem name="Declined" data={claimDataStock? claimDataStock.declinedClaims: 0} url={'/app/claim-settlement/claim/investors/list'} colors="darkred"/>
                </>
              {/* )
             :<><h4>No availabale Data</h4></>
            } */}
            </Grid>

            <Grid style={{marginTop:"30px"}}><h4>{t('Dividends.FCCash')}</h4></Grid>
            <Grid style={{marginTop:"15px"}} container spacing={4}>  
            {/* {claimData ?
              ( */}
                <>
                  <GridItem name="Lodged Claims" data={claimDataFC? claimDataFC.lodgedClaims : 0}url={'/app/claim-settlement/claim/investors/list'} colors="black"/>
                  <GridItem name="Settled" data={claimDataFC? claimDataFC.settledClaims: 0} url={'/app/claim-settlement/claim/investors/list'} colors="green" />
                  <GridItem name="Ongoing" data={claimDataFC? claimDataFC.ongoingClaims: 0}url={'/app/claim-settlement/claim/investors/list'} colors="orange"/>
                  <GridItem name="Declined" data={claimDataFC? claimDataFC.declinedClaims: 0} url={'/app/claim-settlement/claim/investors/list'} colors="darkred"/>
                </>
              {/* )
             :<><h4>No availabale Data</h4></>
            } */}
              <h2 style={{margin:"50px", marginLeft:"450px",  marginBottom:"20px", color:"black"}}>{t('Project.CMSF claims settlement statistics')} </h2>
            </Grid>
      
      {/* </Box> */}
     {/* <Grid>
      <Grid container spacing={2} >
         <Barchart data={chartData}/>
         <AreaCharts data={chartData}/>
      </Grid>
    </Grid> */}
    <Grid container spacing={3} >
              <Paper  variant="outlined"  style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: '48%',
                       borderRadius: 20, 
                       borderColor: '#a4de6c',
                       borderTopWidth: '10px',
                       
                    }}>
                <Grid >
                <BarchartStock data={chartData}/>
                </Grid>
              </Paper>

              <Paper  variant="outlined" square={true} style={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '7px',
                      width: '48%',
                       borderRadius: 20, 
                       borderColor: '#82ca9d',
                       borderBottomWidth: '10px',
                  }}>
                <Grid>
                  <AreaCharts data={chartData}/>
                  {/* <RadialBarChart/> */}
                </Grid>
              </Paper>
           </Grid>
      
{/* ----------------------------------------------------------------------------------------- */}
      <Grid  container spacing= {2} className="row">
        {/* <Grid><Barchart /></Grid>
        <Grid> <BarchartStock /></Grid>  */}
       
       {/* <Barchart /> */}
       {/* <BarchartStock /> */}
        {/* <Grid height ={200} width= {200} container spacing= {10} className="column"> <GridItem name="Lodged Claims (Stock)" data="0" colors="black"/>
              <GridItem name="Settled (Stock)" data="0" colors="green" />
              <GridItem name="Pending (Stock)" data="0" colors="orange"/>
              <GridItem name="Declined (Stock)" data="0" colors="darkred"/></Grid> */}
      </Grid>

      {/* <Grid container spacing={2}>
  <Grid item xs={6} md={8}>
    <Item>xs=6 md=8</Item>
  </Grid>
  <Grid item xs={6} md={4}>
    <Item>xs=6 md=4</Item>
  </Grid>
  </Grid> */}

      </>
    );
  }
  
