import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  HelpOutline as FAQIcon,
  FindInPage,
  Home as HomeIcon, LibraryBooks as LibraryIcon, QuestionAnswer as SupportIcon
} from "@material-ui/icons";
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { getPermissionArray } from "../../utils/strings/userString";



// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  toggleSidebar, useLayoutDispatch, useLayoutState
} from "../../context/LayoutContext";




function Sidebar({ location }) {
  const permissionArray = getPermissionArray();
  const [isDashboard, setIsDashboard] = useState('cmsf')
  var classes = useStyles();
  var theme = useTheme();
  
  const { t } = useTranslation();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [permissionsOfUser, setPermissionsOfUser] = useState([{}]);

  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted.userTypes;
  
      

  const userpermission = localStorage.getItem("permission")

 
  /* 1 */ var canAccessClaimMyDividend = userpermission.includes("Claim-My-Dividend-Read")
  /* 2 */ var canAccessInwardAdd = userpermission.includes("Inward-Dividend-add-Write")
  /* 3 */ var canAccessInwardList = permissionArray.includes("Inward-Dividend-list-Read")
  /* 4 */ var canAccessInwardDivWiseInvestorList = userpermission.includes("Inward-Dividend-list-sub-investor-details")
  /* 5 */ var canAccessInwardDivWiseEdit = userpermission.includes("Inward-Dividend-list-sub-edit")
  /* 6 */ var canAccessInwardDivWiseDelete = userpermission.includes("Inward-Dividend-list-sub-delete")
  /* 7 */ var canAccessInwardDivWiseFileUpload = userpermission.includes("Inward-Dividend-list-sub-file-upload")

  /* 8 */ var canAccessInvestorInfo = permissionArray.includes("Investors-Information-Read")
  /* 9 */ var canAccessIssuerAdd = userpermission.includes("Issuer-add-Read")
  /* 10 */ var canAccessIssuerList = userpermission.includes("Issuer-list-Read")
  /* 11 */ var canEditIssuerInfo = userpermission.includes("Issuer-list-sub-edit")
  /* 12 */ var canDeleteAnIssuer = userpermission.includes("Issuer-list-sub-delete")

  /* 13 */ var canAccessClaimListIssuer = userpermission.includes("Claim-List-Issuer-Read")
  /* 14 */ var canAccessClaimListCMSF = userpermission.includes("Claim-List-CMSF-Read")
  /* 15 */ var canAccessClaimListInvestor = userpermission.includes("Claim-List-Investor-Read")

  /* 16 */ var canAccessCMSFDashboard = userpermission.includes("Dashboard-management-Read")
  /* 17 */ var canAccessPolicyDashboard = userpermission.includes("Dashboard-policy-Read")
  /* 18 */ var canAccessInvestorDashboard = userpermission.includes("Dashboard-investor-Read")

  

//  console.log(canAccessClaimMyDividend,canAccessInwardAdd,canAccessInwardList,canAccessInvestorInfo,canAccessIssuerAdd,canAccessIssuerList,canAccessClaimListIssuer,canAccessClaimListCMSF,canAccessClaimListInvestor,canAccessCMSFDashboard,canAccessPolicyDashboard,canAccessInvestorDashboard)
        

//   const getUsersPermissionData = () =>{
//     if (userId){
//     axios.get('api/v1/auth/otp/verify')
//     .then((res) =>{
//         setPermissionsOfUser(res.data.payload)
//     })
//     .catch((err) =>{
//       console.log(err)
//     })
//   }
// }

  // useEffect(()=>{
  //   getUsersPermissionData();
  // },[userId]);

// console.log(permissionsOfUser)

  const structure = [
    // {
    //   id: 0,
    //   label: t('Dashboard.title'),
    //   link: "/app/dashboard",
    //   icon: <HomeIcon />,
    //   children : userType[0] === 'Investor' ? [
    //     { label: t('Dashboard.title3'), link: "/app/dashboard/investors" },
    //   ] : userType[0] === 'Issuer' ? [
    //     { label: t('Dashboard.title1'), link: "/app/dashboard/policy-level" },
    //   ] : userType[0] === 'Admin_CMSF' ? [
    //     { label: t('Dashboard.title2'), link: "/app/dashboard/cmsf-management" },
    //   ] : ""
    // },

    ( canAccessCMSFDashboard == true ? { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/cmsf-management", icon: <HomeIcon /> }:false),
    ( canAccessPolicyDashboard == true ? { id: 16, label: t('Dashboard.title'), link: "/app/dashboard/policy-level", icon: <HomeIcon /> }:false),
    ( canAccessInvestorDashboard == true ? { id: 17, label: t('Dashboard.title'), link: "/app/dashboard/investors", icon: <HomeIcon /> }:false),
  
    // (canAccessCMSFDashboard),
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/cmsf-management",  icon: <HomeIcon /> },
    // (canAccessPolicyDashboard),
    //   { id: 15, label: t('Dashboard.title'), link: "/app/dashboard/policy-level"  },
    // (canAccessInvestorDashboard),
    //   { id: 16, label: t('Dashboard.title'), link: "/app/dashboard/investors"  },
  //----------------------------------------------------------------
    //  ( canAccessPolicyDashboard === true ? 
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/policy-level",  icon: <HomeIcon />, } :

    //   canAccessInvestorDashboard === true ? 
    //   { id: 0, label: t('Dashboard.title'), link: "/app/dashboard/investors",  icon: <HomeIcon />, } :  false),


    (canAccessInwardAdd === true || canAccessInwardList === true ?
    {
      id: 1,
      label: t('inwardDividend.titleSideBar'),
      link: "/app/inward-dividend",
      icon: <MonetizationOnIcon />,
      children: 
        canAccessInwardAdd === true  && canAccessInwardList === true ?
        [
          { label: t('inwardDividend.titleSideBarListOne'), link: "/app/inward-dividend/new-add" },
          { label: t('inwardDividend.titleSideBarListTwo'), link: "/app/inward-dividend/list" },
        ] :
        canAccessInwardAdd === true  && canAccessInwardList === false ?
          [{ label: t('inwardDividend.titleSideBarListOne'), link: "/app/inward-dividend/new-add" }] : 
        [{ label: t('inwardDividend.titleSideBarListTwo'), link: "/app/inward-dividend/list" } ]
      
    } :  false),

    ( canAccessInvestorInfo == true ? { id: 2, label: t('investorsInformation.titleSideBar'), link: "/app/investor-informations", icon: <GroupIcon /> }:false),

    
    (canAccessClaimMyDividend === true?{ id: 3, label: t('searchDividend.title'), link: "/app/search-dividend", icon: <FindInPage />}:false),




    canAccessClaimListCMSF === true  || canAccessClaimListIssuer === true  ||  canAccessClaimListInvestor === true ?
    {
      id: 4,
      label: t('claimSettlement.title'),
      link: "/app/lodged-claim/list",
      icon: <ListAltIcon />,
      children: 
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === true?[
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" }
      ]:
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === false?[
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" }
      ]:
      canAccessClaimListCMSF === false && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === true?[
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === false?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===false && canAccessClaimListInvestor === true?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" }
      ]:
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === false?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" },
      ]: 
      canAccessClaimListCMSF === true && canAccessClaimListIssuer ===true && canAccessClaimListInvestor === true?[
        { label: t('claimListCmsf.titleSideBar'), link: "/app/claim-settlement/claim/cmsf/list" },
        { label: t('claimListIssuer.titleSideBar'), link: "/app/claim-settlement/claim/issuer/list" },
        { label: t('claimListInvestors.titleSideBar'), link: "/app/claim-settlement/claim/investors/list" },
      ] : console.log("no case")
      
    } : "",


    canAccessIssuerAdd === true || canAccessIssuerList === true?
    { 
      id: 5,
      label: t('Issuer.sideBarMainTitle'),
      link: "/app/issuer/list",
      icon: <LocationCityIcon />,
      children:  canAccessIssuerAdd === true  && canAccessIssuerList === false ?[
        { label: t('Issuer.sideBarAddTitle'), link: "/app/issuer/new-add" },
      ]:
      canAccessIssuerAdd === false  && canAccessIssuerList === true ?[
        { label: t('Issuer.sideBarListTitle'), link: "/app/issuer/list" },
      ]:
      canAccessIssuerAdd === true  && canAccessIssuerList === true ?[
        { label: t('Issuer.sideBarAddTitle'), link: "/app/issuer/new-add" },
        { label: t('Issuer.sideBarListTitle'), link: "/app/issuer/list" },
      ]:
      canAccessIssuerAdd === false  && canAccessIssuerList === false ?[
      ] : ''
    } : '' ,

    // Add Bank-BO
    userType[0] === 'Super_Admin' || userType[0] === 'Admin_CMSF'?
    {
      id: 15,
      label: t('BankBo.titleSideBar') ,
      link: "/app/add-receive-account",
      icon: <PermDataSettingIcon />,
      children: [
        { label: t('BankBo.titleSideBarListOne') , link: "/app/add-receive-account/bank" },
        { label: t('BankBo.titleSideBarListTwo') , link: "/app/add-receive-account/bo" },
      ],
    }: '',

    userType[0] === 'Super_Admin' ?
    {
      id: 6,
      label: t('Roles.sideBarMainTitle'),
      link: "/app/role",
      icon: <PermDataSettingIcon />,
      children: [
        { label: t('Roles.sideBarTitle1'), link: "/app/role" },
        { label: t('Roles.sideBarTitle2'), link: "/app/permissions" },
        { label: t('Roles.sideBarTitle3'), link: "/app/permission-types" },
      ],
    }: '',


    userType[0] === 'Super_Admin' ?
    {
      id: 7,
      label: t('UserM.sideBarMainTitle'),
      link: "/app/user-role",
      icon: <GroupAddIcon />,
      children: [
        // { label: t('UserM.sideBarTitle1'), link: "/app/user-role" },
        { label: t('UserM.sideBarTitle2'), link: "/app/user-permission" },
        { label: t('UserM.sideBarTitle3'), link: "/app/user-investor" },
        { label: t('UserM.sideBarTitle4'), link: "/app/user-issuer" },
        { label: t('UserM.sideBarTitle5'), link: "/app/user-cmsf" },
      ]
    }: '',
    { id: 8, type: "divider" },
    { id: 9, type: "title", label: "" },
    { id: 10, label: t('sideBarBottom.Title1'), link: "https://cmsfbd.org/", icon: <LibraryIcon /> },
    { id: 11, label: t('sideBarBottom.Title2'), link: "https://cmsfbd.org/contact-us", icon: <SupportIcon /> },
    { id: 12, label: t('sideBarBottom.Title3'), link: "https://cmsfbd.org/faq", icon: <FAQIcon /> },
    // { id: 13, label: t('sideBarBottom.Title4')},
    { id: 14, type: "divider" },
   
  ];
 
  // link: `${window.open('https://cmsfbd.org')}`

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link=> (
          link?
          <SidebarLink
            key={link?.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          /> : console.log(link)
          
        ))}
      </List>
      
    </Drawer>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
