import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";


export default function InvestorViewTable({testState, setTestState}){
  const userpermission = localStorage.getItem("permission")
  /* 19 */ var canAccessApproveInwardDividend = userpermission.includes("Inward-Dividend-list-sub-investorsDetails-sub-approve")

  const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isApprove, setIsApprove] =useState(false)
    const [isDecline, setIsDecline] =useState(false)
    const [isSuccess, setIsSuccess] =useState(false)
    const [rowsAffected, setRowsAffected] = useState([]);
    const [data, setData] = useState("")
    const [date, setDate] = useState("")
    const [inwardDividendSummary, setInwardDividendSummary] = useState()
    const [hit, setHit] = useState()
    const [approveButton, setApproveButton] = useState()
    const [summaryData, setSummaryData] = useState([]);


    const params = useParams();

 
    
    useEffect(() => {

      axios.get(`inward-dividend/${params?.id}`)
      .then((res) =>{
          if(res.data.success){
              setIsSuccess(true)
              setIsLoding(false)
              setInwardDividendSummary(res.data.payload.status)
              const date2 = new Date(res?.data?.payload?.date);
              setDate(date2.toISOString().slice(0, 10));
              setData(res.data.payload)
              console.log(res.data.payload.approveButton);
              setApproveButton(res.data.payload.approveButton)
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })  
      axios.get(`investor-informations/investors-summary-by-inwarddividend/${params?.id}`)
      .then((res) =>{
          if(res.data.success){
            setSummaryData(res.data.payload[0])
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })  
      
      //   axios.post(`investor-informations/inward-dividend/${params.id}`)
      //   .then(res => {
      //     if(res?.data?.payload[1]?.status === 0){
      //     setIsApprove(false)
      //   }else{
      //     //setIsApprove(true)
      //     console.log(isApprove)
      //     console.log(res?.data?.payload[0]?.status)
      //   }
      //   })
      // .catch(err=>alert("There was an error occured!"))

      }, [inwardDividendSummary, params.id,approveButton,rowsAffected]);
      console.log(hit)
      console.log(summaryData)

      const approveHandler = () =>{
        Swal.fire({
          title: t('common.sweetAlert.sure'),
            text: 'Are you sure to Approve all Data at once? Please remember that this can not be undone. ',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: "#DC143C", 
            confirmButtonColor: "#527853",    
            confirmButtonText: 'Yes, Sure',
            cancelButtonText: t('common.sweetAlert.noCancel'),
            //reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) 
            {
              axios.put(`investor-informations/inward-dividend-status/${params?.id}`,{status: 1})
              .then(res => {
                setRowsAffected(res.data.payload.affected);
                setTestState(!testState);
                setIsApprove(true)
                setIsDecline(false);
                console.log(res);
                setHit(1)
                if (res.data.payload.affected === 0) Swal.fire('You have already performed bulk action on this')
                else if (res.data.payload.affected !== 0)Swal.fire(`${res.data.payload.affected} Data Approved`)
                //alert("Successfully Approved All Investors' Information.");
              })
            .catch(err=>alert("There was an error occured!")
            )



            // axios.put(`inward-dividend/status-update/${params?.id}`,{status: 1})
            //   .then(res => {
            //     setInwardDividendSummary(res.data.payload.status)
            //     Swal.fire(
            //       'All Data Approved',  
            //     )
            //   })
            // .catch(err=>alert("There was an error occured!")
            // )
          } 
               else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                      'Not Approved',  
                    );
                }
            });
        }

        const declineHandler = () =>{
          Swal.fire({
            title: t('common.sweetAlert.sure'),
              text: 'Are you sure to Decline all Data? Please remember that this can not be undone. ',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonColor: "#DC143C", 
              confirmButtonColor: "#527853",    
              confirmButtonText: 'Yes, Sure',
              cancelButtonText: t('common.sweetAlert.noCancel'),
              //reverseButtons: true
          }).then((result) => {
              if (result.isConfirmed) {
                axios.put(`investor-informations/inward-dividend-status/${params?.id}`,{status: 4})
                .then(res => {
                  setTestState(!testState);
                  setRowsAffected(res.data.payload.affected);
                  setIsDecline(true)
                  setIsApprove(false)
                  setHit(1)
                  if (res.data.payload.affected === 0) Swal.fire('You have already performed bulk action on this')
                  else if (res.data.payload.affected !== 0)Swal.fire(`${res.data.payload.affected} Data Declined`)
                 
                })
              .catch(err=>alert("There was an error occured!")
              )

            //   axios.put(`inward-dividend/status-update/${params?.id}`,{status: 4})
            //   .then(res => {
            //     setInwardDividendSummary(res.data.payload.status)
            //     Swal.fire(
            //       'All Data Approved',  
            //     )
            //     //alert("Successfully Approved All Investors' Information.");
            //   })
            // .catch(err=>alert("There was an error occured!")
            // )
            
            } 
                 else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire(
                        'Not Declined',  
                      );
                  }
              });
          }
      
      console.log(inwardDividendSummary);
      let content = "";
      content = <div> <Paper style={{maxHeight: 200, overflow: 'auto'}}>
      <Table>
        <TableHead>

        </TableHead>
        <TableBody>
          <TableRow style={{background:'#96B6C5'}}>
            <TableCell>ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Dividend Type</TableCell>
            <TableCell>Total no. of Share</TableCell>
            <TableCell>Total Cash</TableCell>
            <TableCell>Total Foreign Currency</TableCell>
            {/* <TableCell>Right Share</TableCell>
            <TableCell>Bonus Share</TableCell> */}
            
            <TableCell>Year</TableCell>
            <TableCell>Remarks</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>{data.autoId}</TableCell>
            <TableCell>{date}</TableCell>
            <TableCell>{data.dividendType}</TableCell>
            <TableCell>{summaryData.totalnoofshare}</TableCell>
            <TableCell>{summaryData.toalnetdividend}</TableCell>
            <TableCell>{data.total_fc}</TableCell>
            {/* <TableCell>{data.total_stock}</TableCell> */}
            
            {/* <TableCell>{data.total_stock}</TableCell> */}
            <TableCell>{data.year}</TableCell>
            <TableCell>{data.remarks}</TableCell>
            <TableCell>
            {canAccessApproveInwardDividend ? 
            <>
            <Button style={{margin: '3px'}}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              onClick={approveHandler}
              // disabled={inwardDividendSummary ===1 || inwardDividendSummary ===4}
              disabled={inwardDividendSummary !==0|| approveButton}
            >
              {inwardDividendSummary === 1 ? "Approved": "Approve All" }
            </Button>

            <Button style={{margin: '3px'}}
              variant="contained" 
              color="secondary"
              type="submit"
              size="large"
              onClick={declineHandler}
              // disabled={inwardDividendSummary ===1 || inwardDividendSummary ===4}
              disabled={inwardDividendSummary !==0|| approveButton}
            >
              {inwardDividendSummary === 4 ? "Declined": "Decline All" }
            </Button>
            </> : ""}
            </TableCell>
          </TableRow>      
         
        </TableBody>
      </Table>
    </Paper>

    <h1 style={{marginBottom: "50px"}}> </h1></div>;


    return(
        <div>
 {isSuccess && content}

     </div>
    )
}