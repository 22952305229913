import React from 'react';
import {
  Area, AreaChart, CartesianGrid, Label, Legend, Tooltip, XAxis,
  YAxis, ResponsiveContainer
} from 'recharts';

  const data = [
    {
      name: "Jan",
      uv: 40,
      pv: 24,
      amt: 240,
    },
    {
      name: "Feb",
      uv: 30,
      pv: 13,
      amt: 225,
    },
    {
      name: "Mar",
      uv: 20,
      pv: 98,
      amt: 229,
    },
    {
      name: "Apr",
      uv: 27,
      pv: 39,
      amt: 200,
    },
    {
      name: "May",
      uv: 18,
      pv: 48,
      amt: 100,
    },
    {
      name: "Jun",
      uv: 23,
      pv: 38,
      amt: 250,
    },
    {
      name: "Jul",
      uv: 34,
      pv: 43,
      amt: 300,
    },
    {
      name: "Aug",
      uv: 34,
      pv: 43,
      amt: 350,
    },
    {
      name: "Sep",
      uv: 34,
      pv: 43,
      amt: 400,
    },
    {
      name: "Oct",
      uv: 34,
      pv: 40,
      amt: 305,
    },
    {
      name: "Nov",
      uv: 34,
      pv: 30,
      amt: 462,
    },
    {
      name: "Dec",
      uv: 34,
      pv: 43,
      amt: 150,
    },
  ];

const AreaCharts = (props) => {
  return (

<ResponsiveContainer width="93%" height={400}>
      <AreaChart width={900} height={450}  spacing ={2} data={props.data} margin={{ top: 60, right: 20,  bottom: 20 , left: 30}}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
        </defs>
          
        <XAxis dataKey="monthyear">
        <Label value="Month" offset={-10} position="insideBottomRight" />
        <Label value="Stock Claim Settlement" offset={340} position="insideBottomRight" />
        </XAxis>
        {/* <Label value="Month" offset={-10} position="insideBottomRight" /> */}
        <YAxis label={{ value: 'Number of  Stock', angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="totalclaim" name="Lodged" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
        <Area type="monotone" dataKey="settled" name="Settled" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
      
      </ResponsiveContainer >

  )
}

export default AreaCharts;

