import { Button } from '@material-ui/core';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from '../../../utils/axios';
import Assign from '../userRole/Assign';
import Unassign from '../userRole/Unassign';
import DirectAssign from './DirectAssign';
import DirectUnassign from './DirectUnassign';
import Table from './Table';



function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

  const [permissionsOpened, setPermissionsOpened] = useState(false);
  const [permissionModalData, setPermissionModalData] = useState('');

  const [removePermissionsOpened, setRemovePermissionsOpened] = useState(false);
  const [removePermissionModalData, setRemovePermissionModalData] = useState('');

  const [rolesOpened, setRolesOpened] = useState(false);
  const [rolesModalData, setRolesModalData] = useState('');

  const [removeRolesOpened, setRemoveRolesOpened] = useState(false);
  const [removeRolesModalData, setRemoveRolesModalData] = useState('');



  var [viewRoles,setViewRoles] = useState([]);


    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const assignRolesModal = (e) => {
        setRolesOpened((prevState) => !prevState);
        setRolesModalData(e);
      
    };

    const unAssignRolesModal = (e) => {
        setRemoveRolesOpened((prevState) => !prevState);
        setRemoveRolesModalData(e);
      
    };

    const permissionAssignModal = (e) => {
        setPermissionsOpened((prevState) => !prevState);
        setPermissionModalData(e);
      
    };

    const permissionRemoveModal = (e) => {
        setRemovePermissionsOpened((prevState) => !prevState);
        setRemovePermissionModalData(e);
      
    };




    const permissionConfirmHandler = (idd) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/permissions/${idd}`,{
                }).then((res)=>{
                      //setDeleteOpened((prevState) => !prevState);
                          getData();
                                });
                
              Swal.fire(
                'Deleted!',
                'Selected Role has been deleted.',
                'success'
              )
            }
          })
    }


    const rolesConfirmHandler = (idd) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/permissions/${idd}`,{
                }).then((res)=>{
                      //setDeleteOpened((prevState) => !prevState);
                          getData();
                                });
              
              Swal.fire(
                'Deleted!',
                'Selected Role has been deleted.',
                'success'
              )
            }
          })
    }

    const viewRolesHandler = (id) => {
        axios.get(`/users/${id}/roles`)
        .then((res) => {
            res.data.payload.map(role => {
                if (role.isAssigned==true){
                   var theRole = role.title
                   setViewRoles([...viewRoles , theRole]);
                
                
                }
    })
          })
          
          .catch((err) =>{
              console.log(err)
          })
        
    }

    //console.log(viewRoles)

    const getData = () =>{
        axios.get(`users?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    }

    useEffect(()=>{
       
      },[viewRoles]);

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.get(`users?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
            
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Permissions and Roles of the Sytem Users',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Panel/Module',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'User Type',
                      accessor: 'userTypes'
                    },
                    
                    {
                        Header: 'Email',
                      accessor: 'email'
                    },
                    {
                        Header: 'Contact No',
                      accessor: 'phoneNumber'
                    },
                    
                    {
                        Header: 'Actions',
                        //accessor: 'title',
                      Cell: ({row}) =>
                      
                      <span>
                     
                      <Button 
                      style={{ margin: '5px', backgroundColor: '#84D2C5'}} 
                      variant="contained" 
                      color="default" 
                      onClick={()=>permissionAssignModal(row.original)}
                      >
                      Permissions                          
                      </Button>

                      <Button 
                      style={{ margin: '5px', backgroundColor: '#84D2C5'}}  
                      variant="contained" 
                      color="default" 
                      onClick={()=>permissionRemoveModal(row.original)}
                      >
                      Assigned Permissions                          
                      </Button>

                      {/* <Button 
                      style={{ margin: '5px', backgroundColor: '#45CFDD'}}  
                      variant="contained" 
                      color="default" 
                      onClick={()=>assignRolesModal(row.original)}
                      >
                      Roles                          
                      </Button> */}

                      {/* <Button 
                      style={{ margin: '5px', backgroundColor: '#45CFDD'}} 
                      variant="contained" 
                      color="default" 
                      onClick={()=>unAssignRolesModal(row.original)}
                      >
                      Assigned Roles                          
                      </Button> */}

                    

                      {/* <Button
                      style={{ margin: '5px', backgroundColor: 'blue'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary" 
                      onClick={()=>console.log(row.original.id)}
                      >
                      Edit                          
                      </Button> */}
 
                      {/* <Button 
                      style={{ margin: '5px', backgroundColor: 'red'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary" 
                      onClick={()=>permissionConfirmHandler(row.original.id)}
                      >
                      Delete                          
                      </Button> */}
                     
                     </span>
                     
                    },
                   
                    
                ],
            },
           
        ],
        []
    )

    return (
        <>
        <div>
            <Table 
            getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
           
        </div>

            <DirectAssign open={permissionsOpened} data={permissionModalData} control={permissionAssignModal}/>
            <DirectUnassign open={removePermissionsOpened} data={removePermissionModalData} control={permissionRemoveModal}/>
            {/* <Assign open={rolesOpened} data={rolesModalData} control={assignRolesModal} /> */}
            {/* <Unassign open={removeRolesOpened} data={removeRolesModalData} control={unAssignRolesModal} /> */}

        </>
    )
}

export default FilterTableComponent;