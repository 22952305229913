import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReportStyle from './Report.module.css';
import CmsfLogo from './logo.png'
import Mujib100 from './mujib_100_logo.jpg'
import Golden50 from './50y_logo.jpg'
import styles from './Table.module.css';
import axiosInstance from '../../utils/axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';



const ClaimReport = ({ applicantData }) => {
  const [data, setData] = useState({
    success: '',
    error: '',      
    investorinformationid: "",
    issuerid: "",
    issuerName: "",
    inwarddividendid: "",
    yearDividend: "",
    fullName: "",
    fathersName: "",
    mothersName: "",
    nid:"",
    dob:"",
    warrantNumber: "",
    folioNumber: "",
    phoneNumber: "",
    shareholding: "",
    address: "",
    remarks: "",
    investorsdividendType: "",
    claimType: "",
    grossDividend: "",
    tds: "",
    netDividend: "",
    Actitle: "",
    BankName: " ",
    BranchName: " ",
    AccountNumber: "",
    RoutingNumber: "",
    cheque_leaf_file: "",
    nofshare: "",
    boID: "",
    trecHolderName: "",
    dpID: "",
    cdbl_share_transfer_file: "",
    nid_file: [],
    folio_bo_file: "",
    poa_nid_file: "",
    poa_letter_file: "",
    death_certificate_file: "",
    successor_letter_file: "",
    inheritance_certificate_file: "",
    court_certificate_file: "",
    successor_nid_file: "",
    issuer_approval_status: "",
    issuer_approval_comments: "",
    cmsf_comments: "" ,
    createdAt:"",
    status: ""
    
  });
  const params = useParams();
  const pdfRef = useRef();
  const pdfRef2 = useRef();


  useEffect(()=>{
    // axiosInstance.get(`claim-settlement/${params.id}`)
    axiosInstance.get(`claim-application/${params.id}`)
    .then((res) =>{
        if(res?.data?.payload){
            
            setData({
                ...data,
                claimType: res?.data?.payload.claimType,
                investorsdividendType: res?.data?.payload.investorsdividendType,
                Actitle: res?.data?.payload.Actitle,
                issuerName: res?.data?.payload?.issuer?.name,
                fullName: res?.data?.payload?.fullName,
                fathersName: res?.data?.payload?.fathersName,
                mothersName: res?.data?.payload?.mothersName,
                boID: res?.data?.payload?.boID,
                warrantNumber: res?.data?.payload?.warrantNumber,
                address: res?.data?.payload?.address,
                nid: res?.data?.payload?.nid,
                dob: res?.data?.payload?.dob,
                phoneNumber: res?.data?.payload?.phoneNumber,
                folioNumber: res?.data?.payload?.folioNumber,
                shareholding: res?.data?.payload?.shareholding,
                yearDividend: res?.data?.payload?.yearDividend,
                grossDividend: res?.data?.payload?.grossDividend,
                tds: res?.data?.payload?.tds,
                netDividend: res?.data?.payload?.netDividend,
                status: res?.data?.payload?.status,
                AccountNumber: res?.data?.payload?.AccountNumber,
                BankName: res?.data?.payload?.BankName,
                createdAt: res?.data?.payload?.createdAt,
                BranchName: res?.data?.payload?.BranchName,
                RoutingNumber: res?.data?.payload?.RoutingNumber,
                cheque_leaf_file: res?.data?.payload?.cheque_leaf_file,
                trecHolderName: res?.data?.payload?.trecHolderName,
                nofshare: res?.data?.payload?.nofshare,
                dpID: res?.data?.payload?.dpID,
                nid_file: res?.data?.payload?.nid_file.slice(-1)[0],
                folio_bo_file: res?.data?.payload?.folio_bo_file.slice(-1)[0],
                poa_nid_file: res?.data?.payload?.poa_nid_file.slice(-1)[0],
                poa_letter_file: res?.data?.payload?.poa_letter_file.slice(-1)[0],
                death_certificate_file: res?.data?.payload?.death_certificate_file.slice(-1)[0],
                successor_nid_file: res?.data?.payload?.successor_nid_file.slice(-1)[0],
                successor_letter_file: res?.data?.payload?.successor_letter_file.slice(-1)[0],
                inheritance_certificate_file: res?.data?.payload?.inheritance_certificate_file.slice(-1)[0],
                court_certificate_file: res?.data?.payload?.court_certificate_file.slice(-1)[0],
              });
        }
        console.log("data:::")
        console.log(res.data)
        console.log("data::")
    })
    .catch((err) =>{
      alert("There was an error occured!")
    })
},[]);

  //Single page
  // const generatePdf = () => {
  //   html2canvas(pdfRef.current).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     pdf.addImage(imgData, 'PNG', 20, 20, 170, 257);  // Adjust margins and dimensions
  //     pdf.save('job_application_report.pdf');
  //   });
  // };


  const generatePdf = () => {

    const pdf = new jsPDF('p', 'mm', 'a4');
  
    html2canvas(pdfRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 20, 20, 170, 257); 
      pdf.addPage();
 
      html2canvas(pdfRef2.current).then(secondCanvas => {
        const secondImgData = secondCanvas.toDataURL('image/png');
        pdf.addImage(secondImgData, 'PNG', 20, 20, 170, 257); 
        pdf.deletePage(2);

        pdf.save('Claim_Report.pdf');
      });
    });
  };
  
  let dividendContent ='' ;
  let enclosureContent ='' ;

if(data.claimType==='Self') 
{enclosureContent = <ul className={styles.fileList}>
               <li className={styles.fileListItem}>Investor's Bank Cheque Leaf</li>
     </ul>}

 if(data.claimType==='Authorized') 
 {enclosureContent = <ul className={styles.fileList}>
        <li className={styles.fileListItem}>Authorization Letter</li>
        <li className={styles.fileListItem}>Authorized Person's NID</li>
        <li className={styles.fileListItem}>Authorized Person's Bank Cheque Leaf</li>
      </ul>}

if(data.claimType==='PowerOfAttorney') 
{enclosureContent = <ul className={styles.fileList}>
       <li className={styles.fileListItem}>Power of Attorney Letter</li>
       <li className={styles.fileListItem}>Power of Attorney Person's NID</li>
       <li className={styles.fileListItem}>Power of Attorney Person's Bank Cheque Leaf</li>
     </ul>}

     
if(data.claimType==='Successor') 
{enclosureContent = <ul className={styles.fileList}>
       <li className={styles.fileListItem}>Investor's Death Certificate</li>
       <li className={styles.fileListItem}>Court Succession</li>
       <li className={styles.fileListItem}>Inheritance Letter</li>
       <li className={styles.fileListItem}>Successor's Person's NID</li>
       <li className={styles.fileListItem}>Successor's Person's Bank Cheque Leaf</li>
     </ul>}

  
  if(data?.investorsdividendType === 'CashDividend' || data?.investorsdividendType === 'PublicSubscriptionMoney' || data?.investorsdividendType === 'FCCashDividend' || data?.investorsdividendType === 'FCPublicSubscriptionMoney'){
    dividendContent = <div>
    <table className={styles.table}>
      <tr>
        <th className={styles.td}>Dividend Year</th>
        <td className={styles.td}>{data?.yearDividend}</td>
      </tr>
      <tr>
        <th className={styles.td}>Gross Dividend</th>
        <td className={styles.td}>{data?.grossDividend}</td>
      </tr>     
      <tr>
        <th className={styles.td}>TDS</th>
        <td className={styles.td}>{data?.tds}</td>
      </tr>
      <tr>
        <th className={styles.td}>Net Dividend</th>
        <td className={styles.td}>{data?.netDividend}</td>
      </tr>
    </table>
    </div>
  }

 

  if(data?.investorsdividendType === 'StockBonusShare' || data?.investorsdividendType === 'StockRightShare' ){
    dividendContent =
    <div>
      <table className={styles.table}>
        <tr>
          <th className={styles.td}>Dividend Year</th>
          <td className={styles.td}>{data?.yearDividend}</td>
        </tr>
        <tr>
          <th className={styles.td}>No of Share</th>
          <td className={styles.td}>{data?.nofshare}</td>
        </tr>     
      </table>
      </div>
  }

  let paymetContent = '';
  if(data?.investorsdividendType === 'CashDividend' || data?.investorsdividendType === 'PublicSubscriptionMoney' || data?.investorsdividendType === 'FCCashDividend' || data?.investorsdividendType === 'FCPublicSubscriptionMoney'){
    paymetContent = <div>
    <table className={styles.table}>
      <tr>
        <th className={styles.td}>Account Name</th>
        <td className={styles.td}>{data?.Actitle}</td>
        <th className={styles.td}>Branch</th>
        <td className={styles.td}>{data?.BranchName}</td>
      </tr>
      <tr>
        <th className={styles.td}>Account Number</th>
        <td className={styles.td}>{data?.AccountNumber}</td>
        <th className={styles.td}>Routing</th>
        <td className={styles.td}>{data?.RoutingNumber}</td>
      </tr>     
      <tr>
        <th className={styles.td}>Bank Name</th>
        <td className={styles.td} colSpan={3}>{data?.BankName}</td>
      </tr>
    </table>
    </div>
  }
  if(data?.investorsdividendType === 'StockBonusShare' || data?.investorsdividendType === 'StockRightShare'){
    paymetContent = <div>
    <table className={styles.table}>
      <tr>
        <th className={styles.td}>BO Name</th>
        <td className={styles.td}>{data?.boID}</td>
        <th className={styles.td}>BO ID</th>
        <td className={styles.td}>{data?.boID}</td>
      </tr>
      <tr>
        <th className={styles.td}>Trec Holder Name</th>
        <td className={styles.td}>{data?.trecHolderName}</td>
        <th className={styles.td}>DP ID</th>
        <td className={styles.td}>{data?.dpID}</td>
      </tr>     
    </table>
    </div>
  }


  return (
    <>
    <div ref={pdfRef2} className={ReportStyle.form}> 
    Page- 1
    </div>

    <div ref={pdfRef} className={ReportStyle.form}>

      <div className={ReportStyle.headerLogo}>
          <div className={ReportStyle.CmsfLogo}>
        <img className={ReportStyle.Golden50} src={Golden50} alt='CMSF Logo' />
          </div>
          <div className={ReportStyle.CmsfLogo}>
        <img className={ReportStyle.CmsfLogo} src={CmsfLogo} alt='CMSF Logo' />
          </div>
          <div className={ReportStyle.Mujib100}>
        <img className={ReportStyle.Mujib100} src={Mujib100} alt='CMSF Logo' />
          </div>

      </div>
      <div className={ReportStyle.address}>
        <span>Dhaka Chamber Building(3rd Floor), 65-66 Motijheel C/A, Dhaka-1000</span> <br />
        <span>Tel: +880241052430, Email: claim@cmsfbd.org</span>
      </div>

      <h1><strong>Application for Unpaid Dividend</strong></h1>

      
    
      <h5 className={ReportStyle.GategoryName}>Claim Information</h5>
      <div>
      <table className={styles.table}>
        <tr>
          <th className={styles.td}>Issuer Company</th>
          <td className={styles.td} colSpan={3}>{data?.issuerName}</td>
        </tr>     
        <tr>
          <th className={styles.td}>Dividend Type</th>
          <td className={styles.td}>{data?.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : data?.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : data?.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : data?.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : data?.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : data?.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : data?.investorsdividendType}</td>
          <th className={styles.td}>Claimant Type</th>
          <td className={styles.td}>{data?.claimType}</td>
        </tr>
        <tr>
        <th className={styles.td}>Claim Date</th>
          <td className={styles.td}>{new Date(data?.createdAt).toLocaleDateString("en-GB")}</td>
          <th className={styles.td}>Claim Status</th>
          <td className={styles.td}>{data?.status === 0 ? (
                               'Submitted by Investor'
                             ) : data?.status === 1 ? 
                             (
                                `Received by Issuer `

                             ) : data?.status === 2 ? (
                                `Approved by Issuer`

                             ) : data?.status === 3 ? (
                                 `Cancelled by Issuer`
                             ) : data?.status === 4 ? (
                                 `Received by CMSF`

                            ) : data?.status === 5 ? (
                                 `Scrutinized by CMSF`

                             ) : data?.status === 6 ? (
                                 `Approved by OMC`

                             ) : data?.status === 7 ? (
                                 `Approved by BOG`
                                
                             ) : data?.status === 8 ? (
                                 `Cancelled by CMSF`

                             ) : data?.status === 9 ? (
                                 ` Setteled by CMSF`
                            ) : data?.status
                                }</td>
        </tr>
        
      </table>
    </div>
    <h1></h1>
    <h1></h1>
      <h5 className={ReportStyle.GategoryName}>Claimant Information</h5>
      <div>
      <table className={styles.table}>
        <tr>
          <th className={styles.td}>Full Name</th>
          <td className={styles.td}>{data?.fullName}</td>
          <th className={styles.td}>NID</th>
          <td className={styles.td}>{data?.nid}</td>
        </tr>
        <tr>
        <th className={styles.td}>BO ID</th>
          <td className={styles.td}>{data?.boID}</td>
          <th className={styles.td}>Phone Number</th>
          <td className={styles.td}>{data?.phoneNumber}</td>
        </tr>
        <tr>
        <th className={styles.td}>Folio No</th>
          <td className={styles.td}>{data?.folioNumber}</td>
          <th className={styles.td}>Date of Birth</th>
          <td className={styles.td}>{data?.dob}</td>
          
        </tr>     
        <tr>
          <th className={styles.td}>Share Holding</th>
          <td className={styles.td}>{data?.shareholding}</td>
          <th className={styles.td}>Father Name</th>
          <td className={styles.td}>{data?.fathersName}</td>
        </tr>
        <tr>
          <th className={styles.td}>Warrant No</th>
          <td className={styles.td}>{data?.warrantNumber}</td>
          <th className={styles.td}>Mother Name</th>
          <td className={styles.td}>{data?.mothersName}</td>
        </tr>
        <tr>
          <th className={styles.td}>Address</th>
          <td className={styles.td} colSpan={3}>{data?.address}</td>
        </tr> 
      </table>
    </div>

    <h1></h1>
    <h1></h1>
    <h5 className={ReportStyle.GategoryName}>Dividend Information</h5>
    <div>
      {dividendContent}
    </div>
    <br />
    <br />


    <h5 className={ReportStyle.GategoryName}>Payment Information</h5>
    <div>
      {paymetContent}
    </div>
    
   
{/* //For Stock and Cash Payment Info */}
    {/* <h5 className={ReportStyle.GategoryName}>Payment Information</h5>
    <div className={styles2.container2}>
        <table className={styles2.table}>
          <tr>
            <th className={styles2.th}>Account Name</th>
            <td className={styles2.td}>Maria Anders</td>
          </tr>
          <tr>
            <th className={styles2.th}>Account Number</th>
            <td className={styles2.td}>1324567890123456</td>
          </tr>
          <tr>
            <th className={styles2.th}>Bank Name</th>
            <td className={styles2.td}>Islami Bank Bangladesh Limited.</td>
          </tr>
          <tr>
            <th className={styles2.th}>Branch</th>
            <td className={styles2.td}>Motijheel</td>
          </tr>
          <tr>
            <th className={styles2.th}>Routing No</th>
            <td className={styles2.td}>213456798</td>
          </tr>
        </table>

        <table className={styles2.table}>
          <tr>
            <th className={styles2.th}>BO ID</th>
            <td className={styles2.td}>Maria Anders</td>
          </tr>
          <tr>
            <th className={styles2.th}>BO Name</th>
            <td className={styles2.td}>Alex Anders</td>
          </tr>
          <tr>
            <th className={styles2.th}>Trec Holder Name</th>
            <td className={styles2.td}>321456789</td>
          </tr>
          <tr>
            <th className={styles2.th}>DP ID</th>
            <td className={styles2.td}>321456789</td>
          </tr>
        </table>
    </div> */}

    <h1></h1>
      

      <h6>Declaration</h6>
      <div>
        I hereby certify that the information provided in this application form report is accurate and true to the best of my knowledge.
      </div>
      <br />
     

      <div className={styles.fileListContainer}>
      <h6 className={styles.fileListHeader}>Enclosures:</h6>
      <ul className={styles.fileList}>
        <li className={styles.fileListItem}>Investor's Updated BO Setup / Folio Number / Share Certificate</li>
        <li className={styles.fileListItem}>Investor's NID copy </li>
      </ul>
      {enclosureContent}
    </div>

      <div className={ReportStyle.msgDiv}>
        <span className={ReportStyle.msg}><i>This is a system generated report.</i></span>
      </div>

    </div>
    
     <Button 
     style={{ margin: '10px', marginTop: '20px' , backgroundColor: '#45CFDD'}}  
     variant="contained" 
     color="default" 
     onClick={generatePdf}>Download Claim Report</Button>
    </>
  );
};

export default ClaimReport;
