import { Button, FormControl, Grid, InputLabel, InputAdornment, MenuItem, Select, TextField ,Typography} from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import useStyles from "./../../../utils/validations/useStyles";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Sectors = [
  
    {id: 1,sector: "Bank"},
    {id: 2,sector: "Cement"},
    {id: 3,sector: "Ceramics Sector"},
    {id: 4,sector: "Corporate Bond"},
    {id: 5,sector: "Debenture"},
    {id: 6,sector: "Engineering"},
    {id: 7,sector: "Financial Institutions"},
    {id: 8,sector: "Food & Allied"},
    {id: 9,sector: "Fuel & Power"},
    {id: 10,sector: "G-SEC (T.Bond)"},
    {id: 11,sector: "Insurance"},
    {id: 12,sector: "IT Sector"},
    {id: 13,sector: "Jute"},
    {id: 14,sector: "Miscellaneous"},
    {id: 15,sector: "Mutual Funds"},
    {id: 16,sector: "Paper & Printing"},
    {id: 17,sector: "Pharmaceuticals & Chemicals"},
    {id: 18,sector: "Services & Real Estate"},
    {id: 19,sector: "Tannery Industries"},
    {id: 20,sector: "Telecommunication"},
    {id: 21,sector: "Textile"},
    {id: 22,sector: "Travel & Leisure"}
  
];

const Category =[
  {id: 1,category: "A"},
  {id: 2,category: "B"},
  {id: 3,category: "G"},
  {id: 4,category: "N"},
  {id: 5,category: "Z"},
]

const AddIssuer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [shareNo, setShareNo] = useState(0)
  const [emailError, setEmailError] = useState("");
  const [allValues, setAllValues] = useState({
    // userid: '',
    name:'',
    tradecode: '',
    debutdate : '',
    category:'',
    sector: '',
    company_secretary: '',
    mobile: '',
    officialphone: '1234',
    email: '',
    officialemail: '',
    address:'',
    noofshare:''
    
});

const { t } = useTranslation();
let currentDate = new Date().toJSON().slice(0, 10);

const changeHandler = e => {
  if(e.target.name==='officialemail'){
    validateEmail(e.target.value)
  }
 
    setAllValues( prevValues => {
      if (e.target.name === 'noofshare'){
       var x = parseInt(e.target.value);
       return { ...prevValues,[e.target.name]: parseInt(e.target.value)}
      }
    return { ...prevValues,[e.target.name]: e.target.value}
 })
 }

 const validateEmail = (value) => {
  const emailRegex = /^\S+@\S+\.[a-zA-Z]{2,4}$/;
  if (value === '') {
    setEmailError("");
    return false;
  }else if(!emailRegex.test(value)){
    setEmailError("Invalid Email Address");
    return false;
  } else {
    setEmailError("");
    return true;
  }
};
  
  const submitHandler = (e) =>{ 
        e.preventDefault();
        console.log('addHandler');
        console.log('addHandler');
        
        axios.post('/issuer',allValues     
        ).then((res)=>{
          // console.log(allValues);
          console.log(`Added successfully`);
          Swal.fire({
            icon: 'success',
            title: 'Issuer has been saved',
            showConfirmButton: false,
            timer: 1500
          })
          history.push('/app/issuer/list');
      
        }).catch((err)=>{
            console.log(err);
        });
        ;
       
   }

  //  const navigateToList = () => {
  //      history.push('/app/issuer/list');
  //  }

return(
  <>
      <form onSubmit={submitHandler}>
        {/* {console.log(allValues)} */}
        <Grid>
        <PageTitle title={ t('Issuer.addPageTitle')} 
    //     button={<Link to="/app/issuer/list"> <Button
    //   variant="contained"
    //   size="medium"
    //   color="primary"
    // >
    //     { t('Issuer.goToList')}
    // </Button>
    // </Link>}  
    />
              {/* <h1 >{ t('Issuer.addPageTitle')} </h1> */}
              </Grid>
              <h2>{ t('Issuer.addPageSubTitle1')}</h2>

              <Grid container spacing={3}>   
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel  id="issuer-id" > {t('Issuer.insideFormSector')} </InputLabel>
                      <Select 
                            required
                            id="sector"
                            name="sector"
                            label="Sector"
                            defaultValue=""
                            //fullWidth
                            variant="standard"
                            onChange={changeHandler}
                                >
                                {Sectors.map((i)=><MenuItem value={i.sector} key={i.id}>{i.sector}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>

                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <FormControl fullWidth >
                       <InputLabel  id="issuer-id" > {t('Issuer.insideFormCategory')} </InputLabel>
                          <Select 
                              required
                              id="category"
                              name="category"
                              label="Category"
                              defaultValue=""
                              //fullWidth
                              variant="standard"
                              onChange={changeHandler}
                                  >
                                {Category.map((i)=><MenuItem value={i.category} key={i.id}>{i.category}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>
                  
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}> 
                  
                    <TextField 
                        required
                        id="userId"
                        name="userId"
                        label={t('Issuer.insideFormID')}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.requiredAsterisk,
                            outlined: classes.root,
                          },
                        }}
                        fullWidth
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
                  </Grid> */}

                  
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField 
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.requiredAsterisk,
                      outlined: classes.root,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    
                  }}
                            required
                            id="noofshare"
                            type="number"
                            name="noofshare"
                            label={t('Issuer.insideFormNoOfShare')}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            fullWidth
                            autoComplete="id"
                            variant="outlined"
                            onChange={changeHandler}
                  />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField 
                   InputLabelProps={{
                    classes: {
                      asterisk: classes.requiredAsterisk,
                      outlined: classes.root,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    max: `${currentDate}`
                  }}
                            required
                            id="debutdate"
                            type="date"
                            name="debutdate"
                            label={t('Issuer.insideFormDebutDate')}
                            // defaultValue="2017-05-24"
                            fullWidth
                            autoComplete="id"
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            variant="outlined"
                            onChange={changeHandler}
                  />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <TextField style={{marginBottom: 10}}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                                required
                                type="character"
                                
                                id="tradecode"
                                name="tradecode"
                                label={t('Issuer.insideFormTradeCode')}
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.requiredAsterisk,
                                    outlined: classes.root,
                                  },
                                }}
                                defaultValue=""
                                fullWidth
                                variant="outlined"
                                onChange={changeHandler}
                                    />
                                
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField 
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.requiredAsterisk,
                        outlined: classes.root,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      
                    }}
                            required
                            id="name"
                            name="name"
                            label={t('Issuer.insideFormIssuerCompanyNmae')}
                            defaultValue=""
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            onChange={changeHandler}
                            />         
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                        // required
                          id="address"
                          name="address"
                          label={t('Issuer.insideFormAddress')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          
                          fullWidth
                          autoComplete="title"
                          variant="outlined"
                          onChange={changeHandler}
                    />
                    </Grid>

                 {/* <h2 style = {{  marginLeft : 20, marginBottom: 20 }}> { t('Issuer.addPageSubTitle2')}</h2> */}
                 
                 
                {allValues.sector == "Mutual Funds" && <Grid item lg={12} md={12} sm={12} xs={12}> <h5 style = {{   marginTop : 5,  color: "blue" }}> { t('Issuer.mutualFundExeptionHelperText')}</h5></Grid>}
                
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h2>{t('Issuer.addPageSubTitle2')}</h2>
                </Grid>
              
                  <Grid 
                  item lg={5} md={5} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                        required
                          id="company_secretary"
                          name="company_secretary"
                          label={t('Issuer.insideFormCpmpanySecretaryName')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          fullWidth
                          autoComplete="title"
                          variant="outlined"
                          onChange={changeHandler}
                    />
                    </Grid>

                    <Grid item lg={3} md={3} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                          required
                          id="officialphone"
                          name="officialphone"
                          label={t('Issuer.insideFormOfficialPhoneNo')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          type="number"
                          fullWidth
                          autoComplete="title"
                          helperText="contact no must be of 11 -digits"
                          variant="outlined"
                          onChange={changeHandler}
                    />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                         required
                          id="officialemail"
                          name="officialemail"
                          label={t('Issuer.insideFormOfficialEmail')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          type="email"
                          fullWidth
                          autoComplete="title"
                          variant="outlined"
                          onChange={changeHandler}
                    />{emailError && (
                      <Typography variant="caption" color="error">
                        {emailError}
                      </Typography>
                    )}
                    </Grid>

                    <Grid item lg={3} md={3} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                          //required
                          id="mobile"
                          name="mobile"
                          label={t('Issuer.insideFormMobileNo')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          type="number"
                          fullWidth
                          autoComplete="title"
                          variant="outlined"
                          onChange={changeHandler}
                    />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}> 
                      
                      <TextField 
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        
                      }}
                        //  required
                          id="email"
                          name="email"
                          label={t('Issuer.insideFormEmail')}
                          InputLabelProps={{
                            classes: {
                              asterisk: classes.requiredAsterisk,
                              outlined: classes.root,
                            },
                          }}
                          type="email"
                          fullWidth
                          autoComplete="title"
                          variant="outlined"
                          onChange={changeHandler}
                    />
                    </Grid>

                  
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>    
                    
                      
                    <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                              variant="contained"
                              color="primary"
                              type="submit"
                              size="large"
                              onSubmit={submitHandler}
                              disabled={(allValues.officialphone.length!==11) || (emailError=== "Invalid Email Address") }
                            >
                            { t('Issuer.submitButton')}
                    </Button>
  
                    <Link to={'/app/issuer/list'}>
                      <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                              variant="contained"
                              color="primary"
                              size="large"
                           >
                            Back
                        </Button>
                    </Link>
                    </Grid>
            
      </form>
              
      </>
  
)
    
}

export default AddIssuer