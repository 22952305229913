import Backdrop from '@material-ui/core/Backdrop';
import { Button } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "../../../utils/axios";
import Swal from 'sweetalert2'



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'center',
    overflow: "scroll"
  },


  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
}));

export default function Unassign({ open,data, control}) {

      const classes = useStyles();
      const { t } = useTranslation();

      const {id} = data;
      const userId = id;
   
    const [data3,setData3] = useState([]);
    

    const unassignHandler = (idd) => {
      
      // setCanUnassign(idd.rowIndex)
   
     
       
       const values = {
         roles:[
            idd
         ]
       };
       
       
      
       axios.put(`roles/remove-user-roles/${id}`,values     
       ).then((res)=>{
         
         //setCanUnassign(false)
     
       }).catch((err)=>{
           console.log(err);
       });
       ;
       Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: 'Unassigned suscessfully',
        showConfirmButton: false,
        timer: 1500
      }) 
     };
  
   
   


    const getData3 = () =>{
      if (id){
      axios.get(`/users/${id}/assigned-roles`)
      .then((res) =>{
          setData3(res.data.payload)
      })
      .catch((err) =>{
      })
      } 
    }

   

  useEffect(()=>{
    
    getData3();
    
  },[userId]);
  
  

    const columns = [
          {
            name: "title",
            label: "Roles",
            options: {
            filter: true,
            sort: true,
            }
          },
        {
            name: "id",
            label: "Action",
            options: {
         
                  customBodyRender: (value, tableMeta, updateValue) => {
                    //assign[value]= true;
                    //unassign[value]= true;

                    // console.log(value)
                    // console.log(roleId)
                    
                    return (
                      <div className={classes.root}>
                      
                          <Button style={{margin: '5px', backgroundColor: 'darkRed'}}  size="small"  variant="contained" color='primary' 
                            onClick={()=>unassignHandler(value)} 
                          >
                          Unassign
                          </Button>

                      </div>
                    )
                  }
            }
          }
      ]
  
  
  
      const options = {
        selectableRows: 'none',
        enableNestedDataAccess: '.',
      };

      
      
  return (
    <div>
        
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={control}
          disableEscapeKeyDown
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
        >
      
        <Fade in={open}>
          <div className={classes.paper}>
            <MUIDataTable
                  //title={"Permissions"}
                  data={data3}
                  columns = {columns}
                  options={options}
                  highlightOnHover={true}
                  onClose={control}
                  fixedHeader
                  paginate={false}
                  pagination={false}
                  //rowsPerPage ={5} 
                  isRowSelectable
                  caseSensitive={false} 
                  print={true}       
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
