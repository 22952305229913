// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "@material-ui/core";
import { ListAltOutlined, VerifiedUser } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import Table from './Table';

function 



FilterTableComponent({testState,setTestState}) {
    const userpermission = localStorage.getItem("permission")
    /* 19 */ var canAccessApproveInwardDividend = userpermission.includes("Inward-Dividend-list-sub-investorsDetails-sub-approve")
  
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [inwardDividendSummary, setInwardDividendSummary] = useState()
    

    useEffect(() =>{
       
        axios.post(`investor-informations/inward-dividend/${params.id}?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
        axios.post(`investor-informations/inward-dividend/${params.id}?remarks=${remarksValue}&date=${dateValue}&issuer=${issuerValue}&total_cash=${totalCashValue}&total_fc=${totalFcValue}&total_stock=${totalStockValue}&take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(page);
            setShowPageSize(take)
        })
        .catch((err) =>{
            alert("There was a error occured!")
        })
    }
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(false);
    const approvedHandler = (id) =>{
        setOpen(true);
        setId(id);
        
    }
    
    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Father Name',
                        accessor: 'fathersName'
                    },
                    {
                        Header: 'Mother Name',
                        accessor: 'mothersName'
                    },
                    {
                        Header: 'BO Id',
                        accessor: 'boID'
                    },
                    {
                        Header: 'Folio Id',
                        accessor: 'folioNumber'
                    },
                    {
                        Header: 'No of Share',
                        accessor: 'shareholding'
                    },
                    {
                        Header: 'Net Dividend',
                        accessor: 'netDividend'
                    },
                    {
                        Header: 'Dividend Year',
                        accessor: 'yearDividend'
                    }, 
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell:({ value }) => {
                            return <>
                                {value === 1 ? "Approved" : value === 0 ? "Not Approved" : value === 4 ? "Declined" :value === 2 ? "Claim Lodged" :value === 3 ? "Settled" :value === 5 ? "CMSF Declined" :''}
                            </>;
                          },
                    }, 
                    {
                        Header: 'Actions',
                        accessor: 'id',
                        Cell: ({row}) => <>
                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/inward-dividend/investor-informations/investor/view/${row.values.id}`}> 
                            <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View the Investors' variant="contained" color="primary" >
                                <ListAltOutlined  /> 
                            </Button>
                        </Link>
                         {canAccessApproveInwardDividend && row.values.status === 0 ? 
                         <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve' variant="contained" color="primary" 
                            
                            onClick={()=>approvedHandler(row.values.id)} >
                            <VerifiedUser  /> 
                         </Button> : ""}
                        </>
                    }, 
                ],
            },
        ],
        []
    )

    return (
        <div>
            <Table 
            open={open}
            id={id}
            setOpen={setOpen}
            getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            testState={testState}
            setTestState={setTestState}
            />
           
        </div>
    )
}

export default FilterTableComponent;