import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import axios from "../../../utils/axios";
import FilterTableComponent from './FilterTableComponent';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const BODetailsToReceiveDividend = () => {
  const { t } = useTranslation();

  const [data, setData]=useState([]);
  const [allValues, setAllValues] = useState({
      "title": '',
      "accountNumber": '',
      "branch": '',
      "routing": '',
      "companyName": '',
      "swiftNumber": '',
      "address": '',
      "type":""
  });
  const [accountNumberError, setAccountNumberError]=useState(false);
  const changeHandler = e => {
    if (e.target.name === "accountNumber"){
      const passwordRegex = /^[^.]+$/;
     console.log(!passwordRegex.test(e.target.value))
      if(passwordRegex.test(e.target.value)){
        setAccountNumberError("Account Number invalid") 
      }
      else{
        setAccountNumberError(false)
      }
  
    }
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
 })
 }



 const submitHandler = (e) =>{ 
          e.preventDefault();
console.log(allValues.accountNumber.length)
          Swal.fire({
            title: 'Do you want to save the changes?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            // denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios.post('/bank-bo/create-bank-bo',allValues     
              ).then((res)=>{
                setData(res)
                Swal.fire('Saved!', '', 'success')
              }).catch((err)=>{
                console.log(err);
                Swal.fire('The BO ID you have provided already exists','', 'error')
            });
              
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        }


      return (
        <div>
           <form onSubmit={submitHandler}>
            <h2>{t('BankBo.newAddBO.pageTitle')}</h2>
             <Grid container spacing={2}>
             <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel style={{  marginTop: '10px',marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Account Type</InputLabel>
                      <Select style={{ marginTop: '40px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                            required
                            id="type"
                            name="type"
                            label="Bank Account Type"
                            placeholder="Select Account Type"
                            variant="standard"
                            onChange={changeHandler}
                            defaultValue=''
                            >
                                <MenuItem value='Stock' key='Local Currency'>BO Account</MenuItem>
                
                              
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="accountNumber"
                        name="accountNumber"
                        type='number'
                        label="BO ID"
                        helperText="BO ID should be of 16-digits"
                        variant="outlined"
                        placeholder="BO ID"
                        required
                        margin="normal"
                        onChange={changeHandler}
                        />
                </Grid>
                
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="title"
                        name="title"
                        label="Account Name/BO Name"
                        variant="outlined"
                        placeholder="Account Name / BO Name"
                        required
                        margin="normal"
                        onChange={changeHandler}
                        />
                </Grid>
                
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="companyName"
                        name="companyName"
                        label="Broker Name"
                        variant="outlined"
                        placeholder="Broker Name"
                        required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="branch"
                        name="branch"
                        label="Branch"
                        variant="outlined"
                        placeholder="Branch"
                        required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="routing"
                        name="routing"
                        type='number'
                        label="DP ID"
                        helperText="DP ID should be of 6-digits"
                        variant="outlined"
                        placeholder="DP ID"
                        required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="address"
                        name="address"
                        label="Address"
                        variant="outlined"
                        placeholder="Address"
                        //required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>

               
              </Grid>
              
            <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                // onClick={submitHandler}
                disabled={allValues.accountNumber.length !==16 || allValues.routing.length !==6 || accountNumberError !=="Account Number invalid"}
              >
                Submit
            </Button>
                <FilterTableComponent data={data}/>
          </form>
        </div>
      )
    }
    
    export default BODetailsToReceiveDividend