import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import FilterTableComponent from "./FilterTableComponent";

export default function ListInwordDividendComponent(){
    const { t } = useTranslation();

    return(
        <div>
           <PageTitle title={ t('inwardDividend.list.pageTitle')} button={<Link to="/app/inward-dividend/new-add"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('inwardDividend.list.newAddLink')}
    </Button></Link>}  />
   <FilterTableComponent />
           
        </div>
    )
}