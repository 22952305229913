import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import axios from "../../../utils/axios";




const Sectors = [
    {id: 1,sector: "Bank"},
    {id: 2,sector: "Cement"},
    {id: 3,sector: "Ceramics Sector"},
    {id: 4,sector: "Corporate Bond"},
    {id: 5,sector: "Debenture"},
    {id: 6,sector: "Engineering"},
    {id: 7,sector: "Financial Institutions"},
    {id: 8,sector: "Food & Allied"},
    {id: 9,sector: "Fuel & Power"},
    {id: 10,sector: "G-SEC (T.Bond)"},
    {id: 11,sector: "Insurance"},
    {id: 12,sector: "IT Sector"},
    {id: 13,sector: "Jute"},
    {id: 14,sector: "Miscellaneous"},
    {id: 15,sector: "Mutual Funds"},
    {id: 16,sector: "Paper & Printing"},
    {id: 17,sector: "Pharmaceuticals & Chemicals"},
    {id: 18,sector: "Services & Real Estate"},
    {id: 19,sector: "Tannery Industries"},
    {id: 20,sector: "Telecommunication"},
    {id: 21,sector: "Textile"},
    {id: 22,sector: "Travel & Leisure"}
  
];

const Category =[
  {id: 1,category: "A"},
  {id: 2,category: "B"},
  {id: 3,category: "G"},
  {id: 4,category: "N"},
  {id: 5,category: "Z"},
]
function Table({ getDataSync, columns, data, setDataStore, setCurrentPage, currentPage, numberOfPage, showPageSize, setShowPageSize,setNumberOfpage,params }) {
    const { t } = useTranslation();
    const defaultColumn = React.useMemo(
        () => ({
            Filter: "",
        }),
        []
    )
    const MenuProps = {
        PaperProps: {
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
            //height: 100,
            overflowY: 'scroll',
            overflowX: 'scroll'
          },
        },
      };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const nextPageHandler = () =>{        
        if(numberOfPage > currentPage){
            setCurrentPage(currentPage+1);      
        }
    }

    const prevPageHandler = () =>{        
        if(currentPage > 1){
            setCurrentPage(currentPage-1);
        }
    }
    
    const handleFirstPage = () =>{
        if(currentPage !== 1){
            setCurrentPage(1)
        }
    }

    const handleLastPage = () =>{
        if(currentPage !== numberOfPage){
            setCurrentPage(numberOfPage)
        }
    }


    const pSize= [5, 10, 20, 30, 40, 50];

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [issuerNameValue, setIssuerNameValue] = useState("")
    const [tradeCodeValue, setTradeCodeValue] = useState("")
    const [categoryValue, setCategoryValue] = useState("")
    const [sectorValue, setSectorValue] = useState("")


    const dateValue = startDate && endDate ? [startDate, endDate] : null;

    const paramsData = {
        page: currentPage,
        take: showPageSize,
        name: issuerNameValue,
        tradecode: tradeCodeValue,
        category: categoryValue,
        sector: sectorValue,  
    }

    const searchResethandler = () =>{
        setStartDate(null);
        setEndDate(null);
        setIssuerNameValue("");
        setTradeCodeValue("");
        setCategoryValue("");
        setSectorValue("");
    }

    const goToPageHandler = (e) =>{
        if( e === 0) {   
        }
        else if(e < 0){
        }
        else if( e => 1){
            setCurrentPage(e);
        }else{
            setCurrentPage(1); 
        }
    }

    useEffect(()=>{
        axios.post(`issuer/list`,paramsData)
    .then((res) =>{
        setDataStore(res.data.payload)
        if(res.data?.page){
            setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
        }
    })
    .catch((err) =>{
    })

},[issuerNameValue,sectorValue,startDate,endDate,tradeCodeValue, categoryValue, setDataStore,setCurrentPage,setNumberOfpage,showPageSize,currentPage])

useEffect(()=>{
    setCurrentPage(1)
},[tradeCodeValue,issuerNameValue,sectorValue,categoryValue])

    return (
        <div>
    <Grid container spacing={2}>
         {/* <Grid item lg={2} md={2} sm={2} xs={12}> */}
         {/* <span>
             Start Date:{' '}
             <input
                className="form-control"
                value={startDate || ""}
                type="date"
                onChange={e => {
                    setStartDate(e.target.value);
                }}
            placeholder={` search...`}
        />
         </span> */}
         {/* <span>
        End Date:{' '}
            <input
                className="form-control"
                value={endDate || ""}
                onChange={e => {
                    setEndDate(e.target.value);
                }}
                placeholder={` search...`}
                type="date"
            />
         </span> */}
        {/* </Grid> */}

      <Grid item lg={2} md={2} sm={2} xs={12}>
        <span>
            Trade Code:{' '}
            <input
                className="form-control"
                value={tradeCodeValue || ""}
                onChange={e => {
                    setTradeCodeValue(e.target.value);
                }}
                placeholder={`search...`}
            />
        </span>
      </Grid>

      <Grid item lg={2} md={2} sm={2} xs={12}>
        <span>
            Issuer Name:{' '}
            <input
                className="form-control"
                value={issuerNameValue || ""}
                onChange={e => {
                    setIssuerNameValue(e.target.value);
                }}
                placeholder={` search...`}
            />
        </span>
      </Grid>

      <Grid item lg={2} md={2} sm={2} xs={12}>
        <FormControl fullWidth >
            <InputLabel id="issuer-id" >Select Sector</InputLabel>
                <Select 
                    required
                        labelId="issuer-id"
                        id="demo-simple-select"
                        value={sectorValue}
                        label="Select Issuer"
                        onChange={(e) => setSectorValue(e.target.value)}
                        MenuProps={MenuProps}  
                        >
                        {Sectors.map((i)=><MenuItem value={i.sector} key={i.sector}>{i.sector}</MenuItem>)}
                </Select>
            </FormControl>
      </Grid>

      <Grid item lg={2} md={2} sm={2} xs={12}>
        <FormControl fullWidth >
            <InputLabel id="issuer-id" >Select Category</InputLabel>
                <Select 
                    required
                        labelId="issuer-id"
                        id="demo-simple-select"
                        value={categoryValue}
                        label="Select Issuer"
                        onChange={(e) => setCategoryValue(e.target.value)}
                        >
                        {Category.map((i)=><MenuItem value={i.category} key={i.category}>{i.category}</MenuItem>)}
                </Select>
        </FormControl>
      </Grid>

      <Grid item lg={2} md={2} sm={2} xs={12}>
        <span>
            <Button
        variant="contained"
                color="primary"
                size="large"
                style={{margin:"10px"}}
                onClick={searchResethandler}
                >{ t('common.reset')}
            </Button>
        </span>
      </Grid>
    </Grid>
       
        <table className="table" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                                {/* Render the columns filter UI */}
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>

        <ul className="pagination">
            <li className="page-item" onClick={handleFirstPage} disabled={!canPreviousPage}>
                <span style={{cursor: "grab"}} className="page-link">First</span>
            </li>
            <li className="page-item" onClick={prevPageHandler}
             disabled={!canPreviousPage}
             >
                <span style={{cursor: "grab"}} className="page-link">{'<'}</span>
            </li>
            <li className="page-item" onClick={nextPageHandler}
             disabled={!canNextPage}
             >
                <span style={{cursor: "grab"}} className="page-link">{'>'}</span>
            </li>
            <li className="page-item" onClick={handleLastPage} disabled={!canNextPage}>
                <span style={{cursor: "grab"}} className="page-link">Last</span>
            </li>
            <li>
                <a className="page-link">
                    Page{' '}
                    <strong>
                        {currentPage} of {numberOfPage}
                    </strong>{' '}
                </a>
            </li>
            <li>
                <a className="page-link">
                    <input
                        className="form-control"
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            goToPageHandler(e.target.value)
                        }}
                        style={{ width: '100px', height: '20px' }}
                    />
                </a>
            </li>{' '}
            <select
                className="form-control"
                value={showPageSize}
                onChange={e => {
                    setShowPageSize(Number(e.target.value))
                }}
                style={{ width: '120px', height: '38px' }}
            >
                {pSize.map(pageSize => (
                    <option key={pageSize} value={pageSize} >
                        Show {pageSize}
                    </option>
                ))}
            </select>
        </ul>

    </div>
    )
}

export default Table;