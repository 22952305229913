import { Button, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import axios from "../../../utils/axios";
import PermissionModal from "./PermissionModal";
import ReduceContext from "./ReduceContext";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ApplicationInfo } from "./ApplicationInfo";
import { DividendInformation } from "./DividendInformation";
import { IntroductoryInfo } from "./IntroductoryInfo";
import { RequiredDocuments } from "./RequiredDocuments";
import { ReviewInformation } from "./ReviewInformation";

    
    const useStyles = makeStyles((theme) => ({
      button: {
        marginRight: theme.spacing(1),
      },
    }));

  const initialState = {
    success: '',
    error: '',      
    // investorinformationid: "",
    // inwarddividendid: "",
    issuerid: "",
    yearDividend: "",
    fullName: "",
    fathersName: "",
    mothersName: "",
    nid:"",
    dob:"",
    warrantNumber: "",
    folioNumber: "",
    phoneNumber: "",
    shareholding: "",
    address: "",
    remarks: "",
    investorsdividendType: "",
    claimType: "",
    grossDividend: "",
    tds: "",
    netDividend: "",
    Actitle: "",
    BankName: "",
    BranchName: "",
    AccountNumber: "",
    RoutingNumber: "",
    cheque_leaf_file: "",
    nofshare: "",
    boID: "",
    trecHolderName: "",
    dpID: "",
    cdbl_share_transfer_file: "",
    nid_file: "",
    folio_bo_file: "",
    poa_nid_file: "",
    poa_letter_file: "",
    death_certificate_file: "",
    successor_letter_file: "",
    inheritance_certificate_file: "",
    court_certificate_file: "",
    successor_nid_file: "",
    issuer_approval_status: "",
    issuer_approval_comments: "",
    cmsf_comments: ""  ,
    // others_file: "" ,
    boType:"",
    residency:"",
    drivingLicense:"",
    passport:""   
};
    
    function getSteps() {
      return [
        "Introductory Information",
        "Applicant's Information",
        "Dividend & Payment Information",
        "Required Documents",
        "Review",
      ];
    }
   
 
    function getStepContent(step) {
      switch (step) {
        case 0:
          return <IntroductoryInfo />;
        case 1:
          return <ApplicationInfo />;
        case 2:
          return <DividendInformation />;
        case 3:
          return <RequiredDocuments />;
        case 4:
          return <ReviewInformation />;
        default:
          return "unknown step";
      }
    }

    const reducer = (state, action) => {
      switch (action.type) {
          case 'error':
              return {
                  loading: false,
                  post: action.result,
                  error: '',
              };
          case 'success':
              return {
                  loading: false,
                  post: {},
                  error: 'There was a problem fetching!',
              };
          default:
              return state;
      }
  };
    
    const LodgeClaim = () => {
     
      const [data, setData] = useState(initialState);
      const classes = useStyles();
      const history = useHistory();
  
      const [activeStep, setActiveStep] = useState(0);
      const steps = getSteps();
      const [message,setMessage] = useState('');
      
      const [opened, setOpened] = useState(true);
      const [modalData, setModalData] = useState('');
    
        const controlModal = (e) => {
            setOpened((prevState) => !prevState);
            setModalData(e);
        };

      const isStepOptional = (step) => {
        return step === 1 || step === 2;
      };

      const params = useParams();

      const [investorinformationid, setInvestorinformationid] = useState("");
      const [issuerid, setIssuerid] = useState("");
      const [inwarddividendid, setInwarddividendid] = useState("");

      // useEffect(()=>{
      //   axios.get(`investor-informations/${params.id}`)
      //   .then((res) =>{
      //     if(res?.data?.success === true && res?.data?.payload.status === 1){
      //       setInvestorinformationid(res?.data?.payload?.id);
      //       setIssuerid(res?.data?.payload?.issuerid)
      //       setInwarddividendid(res?.data?.payload?.inwarddividendid)
      //       setData({ ...data, fullName: res?.data?.payload?.fullName, 
      //         folioNumber:res?.data?.payload?.folioNumber, 
      //         boID: res?.data?.payload?.boID, 
      //         grossDividend: res?.data?.payload?.grossDividend, 
      //         nofshare: res?.data?.payload?.nofshare, 
      //         netDividend: res?.data?.payload?.netDividend, 
      //         investorsdividendType: res?.data?.payload?.investorsdividendType, 
      //         claimType: res?.data?.payload?.claimType, 
      //         tds: res?.data?.payload?.tds,
      //         yearDividend: res?.data?.payload?.yearDividend
      //        })
      //     }else{
      //       history.push('/app/dashboard');
      //     }
      //   })
      //   .catch((err) =>{
      //     alert("There was an error occured!")
      //   })

      // },[])

      const HandleNext = (deta) => {
        deta.preventDefault();
        if (activeStep == steps.length - 1) {
          const formData = new FormData();
          formData.append('investorinformationid',investorinformationid);
          formData.append('issuerid',data.issuerid);
          formData.append('inwarddividendid',inwarddividendid);
          formData.append('fullName',data.fullName);
          formData.append('fathersName',data.fathersName);
          formData.append('mothersName',data.mothersName);
          formData.append('yearDividend',data.yearDividend);
          formData.append('nid',data.nid);
          formData.append('dob',data.dob);
          formData.append('warrantNumber',data.warrantNumber);
          formData.append('boID',data.boID);
          formData.append('folioNumber',data.folioNumber);
          formData.append('shareholding',data.shareholding);
          formData.append('address',data.address);
          formData.append('remarks',data.remarks);
          formData.append('investorsdividendType',data.investorsdividendType);
          formData.append('claimType',data.claimType);
          formData.append('grossDividend',data.grossDividend);
          formData.append('phoneNumber',data.phoneNumber);
          formData.append('tds',data.tds);
          formData.append('netDividend',data.netDividend);
          formData.append('Actitle',data.Actitle);
          formData.append('BankName',data.BankName);
          formData.append('BranchName',data.BranchName);
          formData.append('AccountNumber',data.AccountNumber);
          formData.append('RoutingNumber',data.RoutingNumber);
          formData.append('cheque_leaf_file',data.cheque_leaf_file);
          formData.append('nofshare',data.nofshare);
          formData.append('trecHolderName',data.trecHolderName);
          formData.append('dpID',data.dpID);
          formData.append('cdbl_share_transfer_file',data.cdbl_share_transfer_file);
          formData.append('nid_file',data.nid_file);
          formData.append('folio_bo_file',data.folio_bo_file);
          formData.append('poa_nid_file',data.poa_nid_file);
          formData.append('poa_letter_file',data.poa_letter_file);
          formData.append('death_certificate_file',data.death_certificate_file);
          formData.append('successor_letter_file',data.successor_letter_file);
          formData.append('inheritance_certificate_file',data.inheritance_certificate_file);
          formData.append('court_certificate_file',data.court_certificate_file);
          formData.append('successor_nid_file',data.successor_nid_file);
          formData.append('successor_letter_file',data.successor_letter_file);
          // formData.append('others_file',data.others_file);
          formData.append('boType',data.boType);
          formData.append('residency',data.residency);
          formData.append('drivingLicense',data.drivingLicense);
          formData.append('passport',data.passport);

          // axios.post(`claim-settlement`,formData)
          axios.post(`claim-application`,formData)
          .then((res) =>{    
              setActiveStep(activeStep + 1);
          })
          .catch((err) =>{
            alert(err.response.data.message)
          })
            
          } else {
          setActiveStep((prevState => prevState + 1))
        }
      };
    
      const handleBack = () => {  
        setActiveStep(activeStep - 1);
        console.log(data.RoutingNumber) 
      };
      
      return (
        <div>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    align="center"
                    color="green" 
                    style={{ display: "block" , }}
                  >
                    {/* optional */}
                  </Typography>
                );
              }

              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
    
          {activeStep === steps.length ? (
            <Typography variant="h3" align="center">
              {message} Successfully claim lodged
            </Typography>
          ) : (
            <>
            <ReduceContext.Provider value={{data, setData}}>
              <FormProvider >
                <form onSubmit={HandleNext}>
                  {getStepContent(activeStep)}
                  <Button 
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                 
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    // disabled={data.RoutingNumber.length !==9 || data.phoneNumber.length!==11 || data.nid.length!==10|| data.nid.length!==13|| data.nid.length!==17}
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </form>
                
              </FormProvider>
              </ReduceContext.Provider>
            </>
          )}
           <PermissionModal open={opened} data={modalData} control={controlModal} />
        </div>
      );
    };
    export default LodgeClaim;