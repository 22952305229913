import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../../../utils/axios";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



// const Sectors = [
  
//     {id: 1,sector: "Bank"},
//     {id: 2,sector: "Cement"},
//     {id: 3,sector: "Ceramics Sector"},
//     {id: 4,sector: "Corporate Bond"},
//     {id: 5,sector: "Debenture"},
//     {id: 6,sector: "Engineering"},
//     {id: 7,sector: "Financial Institutions"},
//     {id: 8,sector: "Food & Allied"},
//     {id: 9,sector: "Fuel & Power"},
//     {id: 10,sector: "G-SEC (T.Bond)"},
//     {id: 11,sector: "Insurance"},
//     {id: 12,sector: "IT Sector"},
//     {id: 13,sector: "Jute"},
//     {id: 14,sector: "Miscellaneous"},
//     {id: 15,sector: "Mutual Funds"},
//     {id: 16,sector: "Paper & Printing"},
//     {id: 17,sector: "Pharmaceuticals & Chemicals"},
//     {id: 18,sector: "Services & Real Estate"},
//     {id: 19,sector: "Tannery Industries"},
//     {id: 20,sector: "Telecommunication"},
//     {id: 21,sector: "Textile"},
//     {id: 22,sector: "Travel & Leisure"}
  
// ];

const RoleType =[
  {id: 1, roleType: "Issuer", label: "Issuer"},
]

const IssuerUserCreate = () => {
  const history = useHistory();
  const [data, setData]=useState([]);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState();
  const [nidIsValid, setNidIsValid] = useState();
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  //const nidRegex = /^(?:\d{10}|\d{13}|\d{17})$/;

  const [allValues, setAllValues] = useState({
    countryCode: 'BD',
    identifier:'',
    firstName: '',
    lastName : '',
    email:'',
    password: '',
    nid: '',
    roleType: '',
    issuerid:''
});




const { t } = useTranslation();

const changeHandler = e => {
  if(e.target.name === 'email'){
    const emailRegex = /^\S+@\S+\.[a-zA-Z]{2,4}$/;
    if (e.target.value === '') {
      setEmailIsValid(false);
    }else if(!emailRegex.test(e.target.value)){
      setEmailIsValid(false)
    } else {
      setEmailIsValid(true);
    }
  };
  if(e.target.name === 'password'){
    const passwordRegex = /^((?=.*[0-9])(?=.*[A-Z])(?=.*\W)).{6,32}$/;
    if (e.target.value === '') {
      setPasswordIsValid(false);
    }
     if(!passwordRegex.test(e.target.value)){
      setPasswordIsValid(false);
    } 
    else {
      setPasswordIsValid(true);
    
    }
  };
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
 })
 }

const passwordCheckHandler = e => {
}

console.log(emailIsValid);
 const getData = () => {
  axios.get(`/issuer`)
      .then((res) =>{
        
        setData(res.data.payload)
      })
      .catch((err) =>{
      })
    
  }
  useEffect(()=>{
    getData();
  
},[])
  
  const submitHandler = (e) =>{ 
    
        e.preventDefault();
       
         axios.post('auth/register-user',allValues     
        ).then((res)=>{
          Swal.fire({
            icon: 'success',
            title: 'Issuer type user added successfully',
            timer: 3000
          })
          history.push('/app/user-issuer');
        }).catch((err)=>{
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
            })
        });
   }

   const navigateToList = () => {
       history.push('/app/user-issuer');
   }

return(
  <>
  
      <form onSubmit={submitHandler}>
        <Grid>
        {/* <PageTitle title={ t('Issuer.addPageTitle')} button={<Link to="/app/issuer/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('Issuer.goToList')}
    </Button></Link>}  /> */}
              {/* <h1 >{ t('Issuer.addPageTitle')} </h1> */}
              </Grid>
              <h2>{ t('UserM.formOfCreateIssuerTypeUser')}</h2>

              <Grid container spacing={5}>   
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel  id="issuer-id" >Select Sector</InputLabel>
                      <Select 
                            required
                            id="sector"
                            name="sector"
                            label="Sector"
                            //defaultValue=""
                            //fullWidth
                            variant="standard"
                            onChange={changeHandler}
                                >
                                {Sectors.map((i)=><MenuItem value={i.sector} key={i.id}>{i.sector}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth >
                       <InputLabel  id="issuer-id" >Select Category</InputLabel>
                          <Select 
                              required
                              id="roleType"
                              name="roleType"
                              label="Role Type"
                              defaultValue = ""
                              variant="standard"
                              onChange={changeHandler}
                                  >
                                {RoleType.map((i)=><MenuItem value={i.roleType} key={i.id}>{i.label}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>

              

              <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel style={{  marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Issuer Company Name</InputLabel>
                      <Select style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                            required
                            id="issuerid"
                            name="issuerid"
                            label="issuerid"
                            placeholder="Issuer Name"
                            variant="standard"
                            onChange={changeHandler}
                            defaultValue=''
                            >
                            {data.map((i)=>
                                <MenuItem value={i.id} key={i.id}>{i.name}  ({i.tradecode}) </MenuItem>
                              )}
                        </Select>
                    </FormControl>
                </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField 
                            required
                            id="firstName"
                            name="firstName"
                            label="firstName"
                            //defaultValue=""
                            fullWidth
                            variant="outlined"
                            label="First Name"
                            onChange={changeHandler}
                            />         
                  </Grid>

               <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField 
                            required
                            id="identifier"
                            type="number"
                            name="identifier"
                            label="Phone Number"
                            helperText={
                              // !phoneNumberIsValid && 
                              'Phone Number must be 11-digits'}
                            fullWidth
                            autoComplete="id"
                            variant="outlined"
                            onChange={changeHandler}
                  />
                  
                  </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="email"
                      name="email"
                      
                      label="E-mail"
                      type="email"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                {!emailIsValid && <Typography variant="caption">
                please provide a valid e-mail
              </Typography>}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="password"
                      name="password"
                      label="Password"
                      helperText={
                        // !passwordIsValid && 
                        'Password should be at least 6 characters with at least one number, one special character and one upper case character'}
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
               
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="nid"
                      name="nid"
                      label="nid"
                      helperText={
                      //   !nidIsValid && 
                        'NID must be a 10, 13, or 17-digit number '}
                      //type="avatar"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={changeHandler}
                />
                </Grid>

     
                  <Button style={{ allignRight: 'true' , marginTop: '20px', marginBottom: '20px' , marginRight: '20px', marginLeft: '20px'}}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            disabled={!emailIsValid || !passwordIsValid || !(allValues.nid.length === 10 || allValues.nid.length ===13 || allValues.nid.length === 17) || !(allValues.identifier.length === 11)}
                            onSubmit={submitHandler}
                          >
                          { t('Issuer.submitButton')}
                  </Button>

                  {<Link to="/app/user-issuer"><Button style={{ marginTop: '20px', marginBottom: '20px' , marginRight: '20px', marginLeft: '20px'}}
                            variant="contained"
                            color="primary"
                            size="large"
                         >
                          { t('Issuer.cancelButton')}
                  </Button></Link>}
                  </Grid>
            
      </form>
              
      </>
  
)
    
}

export default IssuerUserCreate