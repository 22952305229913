import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/Wrappers/Wrappers";
import Form from "./Form";

export default function AddInwordDividendComponent(){
    const { t } = useTranslation();

    return(
        <div>
    <PageTitle title={ t('inwardDividend.newAdd.pageTitle')} button={<Link to="/app/inward-dividend/list"> <Button
      variant="contained"
      size="medium"
      color="primary"
    >
        { t('inwardDividend.newAdd.listLink')}
    </Button></Link>} />
    <div>
    <Form />
    </div>
            
        </div>
    )
}