import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useGetIssuerInfoQuery } from "../../features/inwardDividend/inwardDividendAPI";
import axios from '../../utils/axios';
import Table from './searchList/Table';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f1f3f4',
    '& .MuiOutlinedInput-root': {
      borderRadius: 24,
      '&:hover fieldset': {
        borderColor: '#dadce0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1a73e8',
        borderWidth: 2,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  requiredAsterisk: {
    color: 'red',
  },
}));

export default function SearchDividendForm(props){
const texxt = "আপলোডকৃত জাতীয় পরিচয় পত্রের নামের সাথে ডোমেইন নামের মিল থাকতে হবে। </br> সেক্ষেত্রে ব্যক্তির নামের অন্ততঃ একটি পূর্ণ অংশ* দিয়ে গঠিত ডোমেইন নিবন্ধন করা যাবে। যেমন- Md. Ali Chowdhury নামবিশিষ্ট ব্যক্তি ali.com.bd, mdali.com.bd, alich.com.bd, chowdhury.com.bd, আলী.বাংলা ইত্যাদি কম্বিনেশনের ডোমেইন নিবন্ধন করতে পারবেন ব্যক্তির নামের সংক্ষিপ্ত রুপ (acronym) দিয়ে .com.bd বা .বাংলা ডোমেইন নিবন্ধন করা যাবেনা। যেমনঃ Md. Ali Chowdhury নাম বিশিষ্ট ব্যক্তি mac.com.bd ডোমেইন নিবন্ধন করতে পারবেননা। info.bd নিবন্ধনের ক্ষেত্রে ব্যক্তির নামের সাথে ডোমেইন নামের মিল থাকার উল্লিখিত বাধ্যবাধকতা নেই।";
  const detailsShowHandler =() => {Swal.fire({
    title: 'Are you sure?',
  
    text: texxt,
    icon: 'question',
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, proceed!'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    }
  })};

  const params = useParams();
  const classes = useStyles();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const {  data:issuer} = useGetIssuerInfoQuery();
    const [fullName, setFullName] = useState("");
    const [boIDValue, setBoIDValue] = useState("");
    const [folioNumberValue, setFolioNumberValue] = useState("");
    const [netDividendValue, setNetDividendValue] = useState("");
    const [totalStockValue, setTotalStockValue] = useState("");
    const [yearDividend, setYearDividend] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(" Pending");
    const [issuerName, setIssuerName] = useState('no');
const investorid= ""
    const paramsData = {
        page: currentPage,
        take: showPageSize,
        fullName: fullName,
        boID: boIDValue,
        folioNumber: folioNumberValue,
        netDividend: netDividendValue,
        nofshare: totalStockValue,
        yearDividend: yearDividend,
        issuerid: issuerName,
    }

    const paramsData2 = {
      page: currentPage,
      take: showPageSize,
      fullName: fullName,
      boID: boIDValue,
      folioNumber: folioNumberValue,
      netDividend: netDividendValue,
      nofshare: totalStockValue,
      yearDividend: yearDividend,
  }

    const handleSubmit = (e) => {
    
      if( value === "boid"){
        axios.post(`investor-informations/search`, paramsData2)
        .then((res) =>{
          setDataStore(res.data.payload)
          
          setCurrentPage(1);
              if(res.data?.page){
                  setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
              }
              if(!(res.data?.payload[0])){
                Swal.fire({
                  icon: "warning",
                  title: "Sorry...",
                  text: "No dividend data found with the given information!",
                })
            }
        })
        .catch((err) =>
        {Swal.fire({
          icon: "error",
          title: "Please look again...",
          text: "Something went wrong!",
        });
        })  
      }
      if( value === "folio"){
        
        if(paramsData?.issuerid==='no'){ 
          Swal.fire("Please select Issuer Name!");
      }else{
        axios.post(`investor-informations/search`, paramsData)
        .then((res) =>{
          setDataStore(res.data.payload)
         
          setCurrentPage(1);
              if(res.data?.page){
                  setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
              }
              if(!(res.data?.payload[0])){
                Swal.fire({
                  icon: "warning",
                  title: "Check your information again",
                  text: "No dividend data found with the given information!",
                })
            }
        })
        .catch((err) =>{
          Swal.fire({
            icon: "error",
            title: "Please look again...",
            text: "Something went wrong!",
          });
        })  
      }
      }


      
     
    }
    const getNextPageData = ( take, page) =>{
      axios.get(`investor-informations/search`,paramsData)
      .then((res) =>{
          setDataStore(res.data.payload)
          if(res.data?.page){
              setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })
  }
    const getPrevPageData = ( take, page) =>{
      axios.get(`investor-informations/search`, paramsData)
      .then((res) =>{
          setDataStore(res.data.payload)
          if(res.data?.page){
              setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })
  }
    const getFirstPageData = ( take, page) =>{
      axios.get(`investor-informations/search`, paramsData)
      .then((res) =>{
          setDataStore(res.data.payload)
          if(res.data?.page){
              setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })
  }
    const getLastPageData = () =>{
      axios.post(`investor-informations/search`, paramsData)
      .then((res) =>{
          setDataStore(res.data.payload)
          
          if(res.data?.page){
              setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
          }
      })
      .catch((err) =>{
          alert("There was an error occured!")
      })
  }

    const columns = React.useMemo(
      () => [
          {
              Header: 'Search results',
              columns: [
                  {
                      Header: 'ID',
                      accessor: 'autoId',
                  },
                  {
                      Header: 'Full Name',
                      accessor: 'fullName'
                  },
                  {
                      Header: 'BO Id',
                      accessor: 'boID'
                  },
                  {
                      Header: 'Folio Id',
                      accessor: 'folioNumber'
                  },
                  {
                      Header: 'Issuer Name',
                      accessor: 'issuer.name'
                  },
                  
                  {
                      Header: 'Net Dividend',
                      accessor: 'netDividend'
                  },
                  {
                      Header: 'No of Share',
                      accessor: 'nofshare'
                  },
                  {
                    Header: 'Dividend Year',
                    accessor: 'yearDividend'
                },
                  {
                      Header: 'Action',
                      accessor: 'id',
                      Cell: ({row}) =>
                      <Link  to={`/app/search-dividend/claim/${row.original.id}`}> 
                      <Button 
                      style={{ backgroundColor: 'blue'}}  title='Claim your dividend now' 
                      variant="contained" 
                      color="primary"
                     // onClick={detailsShowHandler}
                      >
                      Claim                          
                      </Button>
                      </Link>
                  },
                  
                 
              ],
          },
      ],
      []
  )
  const [value, setValue] = useState('boid');

  const handleChange = (event) => {
    setValue(event.target.value)
   
  };

  const handleReset = () =>{
    setIssuerName('no')
    setValue('boid');
    setBoIDValue("")
    setFolioNumberValue("")
    setFullName("")
  }

    return(
        <div>
            <Grid container spacing={3} 
            style={{marginTop:'25px'}}>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">You have a:</FormLabel>
                  <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <FormControlLabel value="boid" control={<Radio />} label="BO ID" />
                    <FormControlLabel value="folio" control={<Radio />} label="Folio Number" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth >
                  <InputLabel style={{marginTop:'15px'}} id="issuer-id" required >Select Issuer Name</InputLabel>
                    <Select style={{marginTop:'40px'}} {...props}
                  InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root
                        },
                      }}
                    fullWidth
                      labelId="issuer-id"
                      id="demo-simple-select"
                      value={issuerName}
                      label="Select Issuer"
                      onChange={(e) => setIssuerName(e.target.value)}
                      >
                      {issuer?.payload.map((i)=><MenuItem value={i.id} key={i.id}>{i.name}</MenuItem>)}
                    </Select>
                 
                   </ FormControl>
                  <h4 style={{marginTop:"10px"}}> </h4>
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <TextField {...props}
                  InputLabelProps={{
                  classes: {
                    asterisk: classes.requiredAsterisk,
                    outlined: classes.root
                        },
                      }}
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    variant="outlined"
                    placeholder="Enter Full Name"
                    margin="normal"
                    required
                    value={fullName}
                    onChange={(e)=>setFullName(e.target.value)}
                />  
              <br />
                      
              </Grid>

              <Button style={{height: "50px" , margin:"3px", marginTop:"23px"}}
                variant="contained"
                color="primary"
                type="submit"
                //size="small"
                onClick={handleSubmit}
                disabled={
                (boIDValue?.length !== 16 && folioNumberValue?.length < 4 || !fullName || !issuerName)
                  }
                  
                >Search
              </Button>

              <Button style={{height: "50px", margin:"3px", marginTop:"23px"}}
                //style={{marginLeft:"1px"}}
                variant="contained"
                color="primary"
                type="submit"
                //size="large"
                onClick={handleReset}
                  >Reset
              </Button>
                  <br />
            </Grid>

        {/* <Grid container sx={{ flexDirection: { xs: "column", md: "row"} }}>
          <Grid item xl ={12} lg={12} md={12} sm={12} xs={12}
            wrap='wrap'
            >
              <Table 
              getPrevPageData={getPrevPageData}
              getNextPageData={getNextPageData}
              getFirstPageData={getFirstPageData}
              getLastPageData={getLastPageData}
              boID={boIDValue}
              fullName={fullName}
              totalStockValue={totalStockValue}
              netDividendValue={netDividendValue}
              issuerName={issuerName }
              folioNumber={folioNumberValue}
              showPageSize={showPageSize} 
              setShowPageSize={setShowPageSize} 
              setNumberOfpage={setNumberOfpage} 
              numberOfPage={numberOfPage} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              setDataStore={setDataStore} 
              columns={columns} 
              data={dataStore} 
              params={params}
                />
          </Grid>
        </Grid> */}
        </div>
    )
}