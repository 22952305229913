import { Button } from '@material-ui/core';
import { CallReceived, CancelRounded, DoneAll as DoneAllIcon, GetApp as GertAppIcon, LibraryAddCheck as LibraryAddCheckIcon, ListAltOutlined, PlaylistAddCheck as PlaylistAddCheckIcon, Spellcheck as SpellcheckIcon, VerifiedUser } from "@material-ui/icons";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "../../utils/axios";
import PermissionModal from "../claimSettlement/lodgeCLaim/PermissionModal";
import Table from './Table';

function FilterTableComponent() {
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [issuerAffirmationOpened, setIssuerAffirmationOpened] = useState(false);
    const [issuerAffirmationModalData, setIssuerAffirmationModalData] = useState('');
    // jdfj
 

  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const issuerAffirmationcontrolModal = (e) => {
        setIssuerAffirmationOpened((prevState) => !prevState);
        setIssuerAffirmationModalData(e);
      
    };

    useEffect(() =>{
        // axios.post(`claim-settlement/claim-list-by-issuer`)
        axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    const receiveByIssuer =(id)=>{
   
        Swal.fire({
            title: 'Are you sure that the investor belongs to your company and want to receive this claim?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure'
          }).then((result) => {
            if (result.isConfirmed) {
                // axios.put(`claim-settlement/claim-received-by-issuer/${id}`)
                axios.put(`claim-application/claim-received-by-issuer/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 1){
                // axios.post(`claim-settlement/claim-list-by-issuer`)
                axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
              Swal.fire(
                'Claim Received!',
              )
            }
          })
        

    }
    const approveByIssuer =(id)=>{
        //setIssuerAffirmationOpened((prevState) => !prevState);
       
        Swal.fire({
            title: 'Is this claim Authentic?',
            icon: 'question',
            //text: "You won't be able to revert this!",
            // input: 'text',
            text: 'I confirm that all necessary documents are attached and the claim is authentic',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#8EAC50',
            cancelButtonColor: '#d33',
            footer: "You won't be able to revert this!",
            
            confirmButtonText: 'Yes, Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
        // axios.put(`claim-settlement/claim-approved-by-issuer/${id}`)
        axios.put(`claim-application/claim-approved-by-issuer/${id}`)
        .then((res) =>{
            if(res?.data?.payload?.status === 2){
                //alert("Claim has been successfully Approved")
                // axios.post(`claim-settlement/claim-list-by-issuer`)
                axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
        Swal.fire(
            'Claim Approved!',
          )
        }
      })

    }
    const cancelByIssuer =(id)=>{
     
        Swal.fire({
            title: 'Are you sure to Decline this claim?',
            icon: 'warning',
            text: "What is wrong with the claim?",
            input: 'text',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#8EAC50',
            cancelButtonColor: '#d33',
            footer: "You won't be able to revert this!",
            confirmButtonText: 'Decline & Submit',
            preConfirm: (input) => {
                if (!input || input.trim() === ""){
                    Swal.showValidationMessage("Please state the issue");
                }
                if(input.length>30){
                    Swal.showValidationMessage("Please state within 30 characters");
                }
            },
          }).then((result) => {
            if (result.isConfirmed) {
        // axios.put(`claim-settlement/claim-canceled-by-issuer/${id}`,{"comments":`${result.value}`})
        axios.put(`claim-application/claim-canceled-by-issuer/${id}`,{"comments":`${result.value}`})
        .then((res) =>{
            if(res?.data?.payload?.status === 3){
                //alert("Claim has been successfully Cancelled")
                // axios.post(`claim-settlement/claim-list-by-issuer`)
                axios.post(`claim-application/claim-list-by-issuer`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
        Swal.fire(
            'Claim Declined',
          )
        }
      })

    }
   
    const receivedHandler = (id) => alert("Do not have permission!")

    const columns = React.useMemo(
        () => [
            {
                Header: 'Lodged Claim List Table',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                        Header: 'Full Name',
                        accessor: 'fullName'
                    },
                    {
                        Header: 'Dividend Type',
                        accessor: 'investorsdividendType',
                        Cell: ({row}) => <>
                        {row.values.investorsdividendType === 'CashDividend' ? (
                            'Cash Dividend'
                          ) : row.values.investorsdividendType === 'FCCashDividend' ? 
                          (
                             `FC Cash Dividend`

                          ) : row.values.investorsdividendType === 'StockRightShare' ? (
                             `Stock Right Share`

                          ) : row.values.investorsdividendType === 'StockBonusShare' ? (
                              `Stock Bonus Share`
                          ) : row.values.investorsdividendType === 'PublicSubscriptionMoney'? (
                              `Public Subscription Money`

                         ) : row.values.investorsdividendType === 'FCPublicSubscriptionMoney' ? (
                              `FC Public Subscription Money`

                          )  : (
                             <></>
                         )}</>
                    },
                    {
                        Header: 'Claim Type',
                        accessor: 'claimType'
                    },
                    {
                        Header: 'NID',
                        accessor: 'nid'
                    },
                    {
                        Header: 'Date of Birth',
                        accessor: 'dob'
                    },
                    {
                        Header: 'BO Id',
                        accessor: 'boID'
                    },
                    {
                        Header: 'Folio Number',
                        accessor: 'folioNumber'
                    },
                    {
                        Header: 'Warrant Number',
                        accessor: 'warrantNumber'
                    },
                    {
                        Header: 'Phone Number',
                        accessor: 'phoneNumber'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: ({row}) => {
                          return (
                            <>
                              {row.values.status === 0 ? (
                               'Initiated by Investor'
                             ) : row.values.status === 1 ? 
                             (
                                `Received by Issuer`

                             ) : row.values.status === 2 ? (
                                `Approved by Issuer`

                             ) : row.values.status === 3 ? (
                                 `Cancelled by Issuer`
                             ) : row.values.status === 4 ? (
                                 `Received by CMSF`

                            ) : row.values.status === 5 ? (
                                 `Scrutinized by CMSF`

                             ) : row.values.status === 6 ? (
                                 `Approved by OMC`

                             ) : row.values.status === 7 ? (
                                 `Approved by BOG`
                                
                             ) : row.values.status === 8 ? (
                                 ` Claim Cancelled by CMSF`

                             ) : row.values.status === 9 ? (
                                 `Claim Setteled by CMSF`
                            ) : (
                                <> </>
                            )}
                            </>
                          );
                        }
                      },
                    {
                        Header: 'Action(s)',
                        accessor: '',
                        Cell: ({row}) => <>

                            {row.values.status === 0 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Receive' variant="contained" color="primary" onClick={e=>receiveByIssuer(row.original.id)} ><CallReceived  /></Button>
                            ) : row.values.status === 1 ? (
                                <>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve' variant="contained" color="primary" onClick={e=>approveByIssuer(row.original.id)} ><VerifiedUser  /></Button>
                                 <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel' variant="contained" color="primary" onClick={e=>cancelByIssuer(row.original.id)} ><CancelRounded /></Button>
                                </>
                            ) : row.values.status === 2 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Receive By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><CallReceived  /></Button>
                            ) : row.values.status === 3 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Cancel By Issuer' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled ><CancelRounded style={{backgroundColor: "#DEC20B" ,fill: "white"}} /></Button>
                            ) : row.values.status === 4 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Receive By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><GertAppIcon  /></Button>
                            ) : row.values.status === 5 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Scrutinize By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><PlaylistAddCheckIcon   /></Button>
                            ) : row.values.status === 6 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve By OMC' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><LibraryAddCheckIcon/></Button>
                            ) : row.values.status === 7 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Approve By BOG' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><SpellcheckIcon/></Button>
                            ) : row.values.status === 8 ? (
                                <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Claim Settled By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><DoneAllIcon style={{backgroundColor: "#7A9D54"  , fill: "white"}}/></Button>
                            ) : row.values.status === 9 ? (
                               <>   <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Claim Settled By CMSF' variant="contained" color="primary" onClick={e=>receivedHandler(row.original.id)} disabled><DoneAllIcon style={{backgroundColor: "#7A9D54" , fill: "white"}} /></Button></>
                            ) : (
                                <> </>
                            )}
                        

                        <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/claim-settlement/claim/issuer/view/${row.original.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View the Lodged Claim' variant="contained" color="primary" ><ListAltOutlined  /> </Button></Link>
                        
                        {/* <Link style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} to={`/app/lodged-claim/edit/${row.original.id}`}> <Button style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Edit the Lodged Claim' variant="contained" color="primary" ><EditTwoTone  /> </Button></Link> */}
                    

                        </>
                    },
                ],
            },
        ],
        []
    )
    return (
        <div>
            <Table 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
            <PermissionModal open={issuerAffirmationOpened} data={issuerAffirmationModalData} control={issuerAffirmationcontrolModal} />
        </div>
    )
}

export default FilterTableComponent;