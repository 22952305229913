import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField,Typography } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Link } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import Swal from 'sweetalert2';
import useStyles from "./../../../utils/validations/useStyles";
const Sectors = [
  
  {id: 1,sector: "Bank"},
  {id: 2,sector: "Cement"},
  {id: 3,sector: "Ceramics Sector"},
  {id: 4,sector: "Corporate Bond"},
  {id: 5,sector: "Debenture"},
  {id: 6,sector: "Engineering"},
  {id: 7,sector: "Financial Institutions"},
  {id: 8,sector: "Food & Allied"},
  {id: 9,sector: "Fuel & Power"},
  {id: 10,sector: "G-SEC (T.Bond)"},
  {id: 11,sector: "Insurance"},
  {id: 12,sector: "IT Sector"},
  {id: 13,sector: "Jute"},
  {id: 14,sector: "Miscellaneous"},
  {id: 15,sector: "Mutual Funds"},
  {id: 16,sector: "Paper & Printing"},
  {id: 17,sector: "Pharmaceuticals & Chemicals"},
  {id: 18,sector: "Services & Real Estate"},
  {id: 19,sector: "Tannery Industries"},
  {id: 20,sector: "Telecommunication"},
  {id: 21,sector: "Textile"},
  {id: 22,sector: "Travel & Leisure"}
]


const Category =[
{id: 1,category: "A"},
{id: 2,category: "B"},
{id: 3,category: "G"},
{id: 4,category: "N"},
{id: 5,category: "Z"},
]


export default function Edit() {
  const classes = useStyles();
  const history = useHistory();
    const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isSuccess, setIsSuccess] =useState(false)
    const [isUpdated, setIsUpdated] =useState(false)
    const [emailError, setEmailError] = useState("");

    const [data, setData] = useState({
      id:'',
      name:'',
      tradecode: '',
      debutdate : '',
      category:'',
      sector: '',
    // noofshare: '',
      company_secretary: '',
      mobile: '',
      officialphone: '',
      email: '',
      officialemail: '',
      address: ''
    })

    const params = useParams();

    const changeHandler = e => {
      if(e.target.name==='officialemail'){
        validateEmail(e.target.value)
      }
      setData( prevValues => {
      return { ...prevValues,[e.target.name]: e.target.value}
   })
     
     }


     const validateEmail = (value) => {
      const emailRegex = /^\S+@\S+\.[a-zA-Z]{2,4}$/;
      if (value === '') {
        setEmailError("please provide valid email address");
        return false;
      }else if(!emailRegex.test(value)){
        setEmailError("Invalid Email Address");
        return false;
      } else {
        setEmailError("");
        setData({...data, officialemail: value})
        return true;
      }
    };
   

    useEffect(() => {

        axios.get(`issuer/${params.id}`)
        .then((res) =>{
            if(res?.data?.success){
              const date = new Date(res?.data?.payload?.debutdate);
              const formattedDate = date.toISOString().slice(0, 10);
                setIsSuccess(true)
                setIsLoding(false)
                setData({...data,
                  id: res?.data?.payload?.id,
                  name:res?.data?.payload?.name,
                  tradecode: res?.data?.payload?.tradecode ,
                  debutdate :formattedDate ,
                  category:res?.data?.payload?.category,
                  sector: res?.data?.payload?.sector,
                 // noofshare: '',
                  company_secretary: res?.data?.payload?.company_secretary,
                  mobile: res?.data?.payload?.mobile,
                  officialphone: res?.data?.payload?.officialphone,
                  email: res?.data?.payload?.email,
                  officialemail: res?.data?.payload?.officialemail,
                  address:res?.data?.payload?.address 
                })
                // console.log(data)
                // console.log(res.data.payload)
                
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
      }, [params.id]);
   
const {autoId, id, name, tradecode, debutdate, category, sector} = data;
      
// console.log(data) 

    
    const submitHandler = (e) =>{
      
        e.preventDefault();
       
        console.log('editHandler');
        console.log(data?.id);
        
        axios.put(`/issuer/${data?.id}`,
         data
          ).then((res)=>{
            if (res.data.success) {
              setIsUpdated(true);
              console.log(res)
              console.log(res.status)
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Issuer Information Updated',
                showConfirmButton: false,
                timer: 1500
              })
              history.push('/app/issuer/list')   
            }
          }).catch((err)=>{
            console.log(err);
        });
        ;       
       }
  console.log(debutdate); 

    return(
        <>
         <PageTitle title={ t('Issuer.editPageTitle')} />
         <h2>{ t('Issuer.addPageSubTitle1')}</h2>
            <form onSubmit={submitHandler}>
            <Grid container spacing={3}>   
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel  id="issuer-id" > {t('Issuer.insideFormSector')} </InputLabel>
                      <Select 
                            required
                            id="sector"
                            name="sector"
                            label="Sector"
                            
                            
                            //fullWidth
                            variant="standard"
                            value={data.sector}
                            onChange={(e) => setData({...data, sector: e.target.value})}
                                >
                                {Sectors.map((i)=><MenuItem value={i.sector} key={i.id}>{i.sector}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>

                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <FormControl fullWidth >
                       <InputLabel  id="issuer-id" > {t('Issuer.insideFormCategory')} </InputLabel>
                          <Select 
                              required
                              id="category"
                              name="category"
                              label="Category"
                              value={data.category}
                              //fullWidth
                              variant="standard"
                              onChange={(e) => setData({...data, category: e.target.value})}
                                  >
                                {Category.map((i)=><MenuItem value={i.category} key={i.id}>{i.category}</MenuItem>)}
                      </Select>
                      </FormControl>
                  </Grid>

                  
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}> 
                  
                    <TextField 
                        required
                        id="userId"
                        name="userId"
                        label={t('Issuer.insideFormID')}
                        InputLabelProps={{
                          classes: {
                            asterisk: classes.requiredAsterisk,
                            outlined: classes.root,
                          },
                        }}
                        fullWidth
                        autoComplete="title"
                        variant="outlined"
                        onChange={changeHandler}
                  />
                  </Grid> */}

                  <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField 
                  
                            required
                            id="debutdate"
                            type="date"
                            name="debutdate"
                            label={t('Issuer.insideFormDebutDate')}
                            //defaultValue={"2000-05-24"}
                            value={data.debutdate}
                            fullWidth
                            autoComplete="id"
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            variant="outlined"
                            onChange={(e) => setData({...data, debutdate: e.target.value})}
                  />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField 
                            required
                            id="noofshare"
                            type="number"
                            name="noofshare"
                            value={data.noOfshare}
                            label={t('Issuer.insideFormNoOfShare')}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            fullWidth
                            autoComplete="id"
                            variant="outlined"
                            onChange={changeHandler}
                  />
                  </Grid> */}

                  <Grid item lg={3} md={3} sm={12} xs={12}>
                 <TextField
                            required
                            type="character"
                            id="tradecode"
                            name="tradecode"
                            label={t('Issuer.insideFormTradeCode')}
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            value={data.tradecode}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setData({...data, tradecode: e.target.value})}
                                />
                                
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField 
                            required
                            id="name"
                            name="name"
                            label={t('Issuer.insideFormIssuerCompanyNmae')}
                            value={data.name}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.requiredAsterisk,
                                outlined: classes.root,
                              },
                            }}
                            onChange={(e) => setData({...data, name: e.target.value})}
                            />         
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}> 
                  
                  <TextField
                  
                     // required
                      id="address"
                      name="address"
                      label={t('Issuer.insideFormAddress')}
                      // InputLabelProps=
                      // {{
                      //   classes: {
                      //     asterisk: classes.requiredAsterisk,
                      //     outlined: classes.root,
                      //   },
                      // }}
                      value={data.address}
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={(e) => setData({...data, address: e.target.value})}
                />
                </Grid>
  
            {data.sector == "Mutual Funds" && <Grid item lg={12} md={12} sm={12} xs={12}> <h5 style = {{   marginTop : 5,  color: "blue" }}> { t('Issuer.mutualFundExeptionHelperText')}</h5></Grid>}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <h2>{t('Issuer.addPageSubTitle2')}</h2>
                </Grid>
        
                 <Grid item lg={5} md={5} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="company_secretary"
                      name="company_secretary"
                      value={data.company_secretary}
                      label={t('Issuer.insideFormCpmpanySecretaryName')}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={(e) => setData({...data, company_secretary: e.target.value})}
                />
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}> 
                  
                  <TextField
                      required
                      id="officialphone"
                      name="officialphone"
                      value={data.officialphone}
                      label={t('Issuer.insideFormOfficialPhoneNo')}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      type="number"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={(e) => setData({...data, officialphone: e.target.value})}
                />
                </Grid>

            

                <Grid item lg={4} md={4} sm={12} xs={12}> 
                  
                  <TextField 
                      required
                      id="officialemail"
                      name="officialemail"
                      value={data.officialemail}
                      label={t('Issuer.insideFormOfficialEmail')}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.requiredAsterisk,
                          outlined: classes.root,
                        },
                      }}
                      type="email"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      // onChange={(e) => setData({...data, officialemail: e.target.value})}
                      onChange={changeHandler}
                />
                <Typography variant="caption" color="error">
                        {emailError}
                      </Typography>
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}> 
                  
                  <TextField 
                      //required
                      id="mobile"
                      name="mobile"
                      value={data.mobile}
                      label={t('Issuer.insideFormMobileNo')}
                      // InputLabelProps={{
                      //   classes: {
                      //     asterisk: classes.requiredAsterisk,
                      //     outlined: classes.root,
                      //   },
                      // }}
                      type="number"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={(e) => setData({...data, mobile: e.target.value})}
                />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}> 
                  
                  <TextField
                     // required
                      id="email"
                      name="email"
                      value={data.email}
                      label={t('Issuer.insideFormEmail')}
                      // InputLabelProps={{
                      //   classes: {
                      //     asterisk: classes.requiredAsterisk,
                      //     outlined: classes.root,
                      //   },
                      // }}
                      type="email"
                      fullWidth
                      autoComplete="title"
                      variant="outlined"
                      onChange={(e) => setData({...data, email: e.target.value})}
                />
                </Grid>
          
             </Grid>
           
                 <Button style={{ marginTop: '30px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 type="submit"
                 size="large"
                 onSubmit={submitHandler}
                 disabled={data.officialphone.length!==11 || (emailError=== "Invalid Email Address")}
                
                 
               >
                 { t('Issuer.submitButton')}
               </Button>
                <Link to= "/app/issuer/list" >
                 <Button style={{ marginTop: '30px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                 variant="contained"
                 color="primary"
                 size="large"
                
               >
                  { t('Issuer.cancelButton')}
               </Button>
               </Link>
            </form>
        </>
    )
}