import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";
import FilterTableComponent from './FilterTableComponent';
import { useTranslation } from 'react-i18next';


const BankToReceiveDividend = () => {
  const { t } = useTranslation();

  const [data, setData]=useState([]);
  const [allValues, setAllValues] = useState({
      "title": '',
      "accountNumber": 1256,
      "branch": '',
      "routing": "",
      "companyName": '',
      "swiftNumber": '',
      "address": '',
      "type":"",
  });
  let cashArray = [];

  // useEffect(()=>{

  //     },[allValues])  
  const [accountNumberError, setAccountNumberError]=useState(false);
  const [routingNumberError, setRoutingNumberError]=useState(false);

  const changeHandler = e => {
    if (e.target.name === "accountNumber"){
      const passwordRegex = /^[^.]+$/;
     console.log(!passwordRegex.test(e.target.value))
      if(passwordRegex.test(e.target.value)){
        setAccountNumberError("Account Number invalid") 
      }
      else{
        setAccountNumberError(false)
      }
  
    }
    if (e.target.name === "routing"){
      const routingRegex = /^[^.]+$/;
     console.log(!routingRegex.test(e.target.value))
      if(routingRegex.test(e.target.value)){
        setRoutingNumberError("Account Number invalid") 
      }
      else{
        setRoutingNumberError(false)
      }
  
    }
    setAllValues( prevValues => {
    return { ...prevValues,[e.target.name]: e.target.value}
    
 })
 }

//  console.log(allValues);

 const submitHandler = (e) =>{ 
           e.preventDefault();
          Swal.fire({
            title: 'Do you want to save the changes?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            // denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              axios.post('/bank-bo/create-bank-bo',allValues     
          ).then((res)=>{
            setData(res.data.payload)
            setAllValues({
              "title": '',
              "accountNumber": 1253546,
              "branch": '',
              "routing": "123456897",
              "companyName": '',
              "swiftNumber": '',
              "address": '',
              "type":"",
          });
            Swal.fire('Saved!', '', 'success')
            
          }) .catch((err)=>{
            console.log(err);
            Swal.fire('The Bank Account you have provided already exists','', 'error')
        });    
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
      
        }

// helooo

      return (
        <div>
           <form onSubmit={submitHandler}>
            <h2>{t('BankBo.newAddBank.pageTitle')}</h2>
             <Grid container spacing={2}>
             <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <InputLabel style={{  marginTop: '10px',marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} id="issuer-id" >Select Account Type</InputLabel>
                      <Select style={{ marginTop: '40px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                            required
                            id="type"
                            name="type"
                            label="Bank Account Type"
                            placeholder="Select Account Type"
                            variant="standard"
                            onChange={changeHandler}
                            defaultValue=''
                            >
                                <MenuItem value='Cash' key='Local Currency'>Local Currency</MenuItem>
                                <MenuItem value='FC' key='Foreign Currency'>Foreign Currency</MenuItem>
                              
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                  required
                        fullWidth
                        id="companyName"
                        name="companyName"
                        label="Bank Name"
                        variant="outlined"
                        placeholder="Bank Name"
                        
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                  required
                        fullWidth
                        id="title"
                        name="title"
                        label="Account Name"
                        variant="outlined"
                        placeholder="Account Name"
                        
                        margin="normal"
                        onChange={changeHandler}
                        />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                   required
                        fullWidth
                        type="number"
                        id="accountNumber"
                        name="accountNumber"
                        label="Account Number"
                        variant="outlined"
                        placeholder="Account Number"
                        helperText="Account Number should be between 11-16 digits"
                       
                        margin="normal"
                        onChange={changeHandler}
                        />
                </Grid>
                
      
      
     
      {/* "companyName": '',
      "swiftNumber": '',
      "": '',
      "type": "Local Currency Bank/Foreign Currency Bank  " */}

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        required
                        fullWidth
                        id="branch"
                        name="branch"
                        label="Branch"
                        variant="outlined"
                        placeholder="Branch"
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        required
                        fullWidth
                        type="number"
                        id="routing"
                        name="routing"
                        helperText="routing number should be of 9-digits"
                        label="Routing Number"
                        variant="outlined"
                        placeholder="Routing Number"
                        //required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="address"
                        name="address"
                        label="Address"
                        variant="outlined"
                        placeholder="Address"
                        //required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                        fullWidth
                        id="swiftNumber"
                        name="swiftNumber"
                        label="Swift Number"
                        helperText='swift number must be atleast 8 and maximum 11 characters'
                        variant="outlined"
                        placeholder="Swift Number"
                        //required
                        margin="normal"
                        onChange={changeHandler}
                  />
                </Grid>
                
              </Grid>
              
            <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                // onClick={submitHandler}
                disabled={allValues.routing.length !==9 || accountNumberError !=="Account Number invalid" || allValues.accountNumber.length >16 ||  allValues.accountNumber.length <11 ||routingNumberError !=="Account Number invalid" }
              >
                    Submit
            </Button>
                <FilterTableComponent data={data}/>
          </form>
        </div>
      )
    }
    
    export default BankToReceiveDividend