import { FormHelperText, Grid, InputAdornment, TextField } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import ReduceContext from "./ReduceContext";
import useStyles from "./useStyle";

export const DividendInformation = () => {
  const classes = useStyles();
  const [photoOfChequeLeaf, setPhotoOfChequeLeaf] = useState('')
  const {data, setData} = useContext(ReduceContext);
  const [AccountNumberHelperText, setAccountNumberHelperText] = useState('');
 

  const [error, setError] = useState(false);
  
  const [routingHelperText, setRoutingHelperText] = useState('');

  function checkFileType(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    if(allowedFileTypes.includes(file.type)){
      return true;
    } else{
      alert( " Plese check your upload file type. Only allow (PNG/JPG/JPEG/PDF)")
      return false
    }
  }

  const handleAccountNumber = (event) => {
    const value = event.target.value;
    setData({...data, AccountNumber: value})
    const isValidLength = (value.length === 16);
    const isValid = /^[^.]+$/.test(value);
    setError(!isValidLength);
    setError(isValid);
    console.log(isValid);
    setAccountNumberHelperText((isValidLength && isValid) ? '' : 'Please Enter Valid Account Number');
  };

  
  const handleRoutingInputChange = (event) => {
    const value = event.target.value;
    setData({...data, RoutingNumber: event.target.value})
    const isRoutingLengthValid = value.length === 9;

    setError(!isRoutingLengthValid);
    setRoutingHelperText(isRoutingLengthValid ? '' : 'Routing Number must be 9-digits');
  };

  let dividendContent ='' ;
  
  // if(data?.investorsdividendType === 'CashDividend' || data?.investorsdividendType === 'PublicSubscriptionMoney' || data?.investorsdividendType === 'FCCashDividend' || data?.investorsdividendType === 'FCPublicSubscriptionMoney'){
    dividendContent = <div>
      <Grid container spacing={4}>
      <Grid item lg={6} md={6} sm={12} xs={12}> 

         <br />      
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
             fullWidth
            id="dividend-year"
            label="Dividend Year"
            variant="outlined"
            placeholder="Enter Your Dividend Year"
            required
            margin="normal"
            value={data.yearDividend? data.yearDividend:''}
            onChange={(e) => setData({...data, yearDividend: e.target.value})}
          /> <br />
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
             fullWidth
            id="gross-dividend"
            label="Gross Dividend"
            type="number"
            variant="outlined"
            helperText="Enter 0 if exact value in not known"
            placeholder="Enter Gross Dividend"
            required
            margin="normal"
            value={data.grossDividend ? data.grossDividend :''}
            onChange={(e) => setData({...data, grossDividend: e.target.value})}
          /> <br />
           <TextField
           InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
        fullWidth
            id="TDS"
            required
            label="TDS"
            helperText="Enter 0 if exact value in not known"
            variant="outlined"
            placeholder="Enter TDS"
            type="number"
            margin="normal"
            value={data.tds ? data.tds :''}
            //defaultValue={0}
            onChange={(e) => setData({...data, tds: e.target.value})}
          /> <br />
           <TextField
           InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
        fullWidth
            id="net-dividend"
            label="Net Dividend"
            variant="outlined"
            placeholder="Enter Net Dividend"
            type="number"
            required
            margin="normal"
            value={data.netDividend ? data.netDividend:''}
            onChange={(e) => setData({...data, netDividend: e.target.value})}
          /> <br />
            <TextField
          InputProps={{
          startAdornment: (
            <InputAdornment position="start"><AttachFile/>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
              id="cheque-leaf"
              label="Cheque Leaf (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your Cheque Leaf"
              fullWidth
              focused
              required={!data.cheque_leaf_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, cheque_leaf_file: e.target.files[0]})
                  setPhotoOfChequeLeaf(URL.createObjectURL(e.target.files[0]));
                }else{
                  e.target.value = null;
                  setData({...data, cheque_leaf_file: []});
                }
              } }
            /> 
            {photoOfChequeLeaf? <div><img style={{height: "200px", weight: "200px"}} src={photoOfChequeLeaf} /></div>: ""}
            
          </Grid>
          <Grid  item lg={6} md={6} sm={12} xs={12}>
          <br />
          <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
        fullWidth
            id="account-title"
            label="Bank Account Name"
            variant="outlined"
            placeholder="Enter Account Title"
            required
            margin="normal"
            value={data.Actitle}
            onChange={(e) => setData({...data, Actitle: e.target.value})}
          />
          <TextField
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
             fullWidth
            id="account-number"
            label="Account Number"
            variant="outlined"
            placeholder="Enter Your Account Number"
            type="number"
            required
            margin="normal"
            value={data.AccountNumber}
            // onChange={(e) => setData({...data, AccountNumber: e.target.value.replace(/[^0-9]/g, "")})}
            onChange={handleAccountNumber}
          /><FormHelperText >{AccountNumberHelperText} </FormHelperText>
          <FormHelperText >Account Number should be of 16-digits</FormHelperText>
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
             fullWidth
            id="bank-name"
            label="Bank Name"
            variant="outlined"
            placeholder="Enter Bank Name"
            required
            margin="normal"
            value={data.BankName}
            onChange={(e) => setData({...data, BankName: e.target.value})}
          /> <br />
           <TextField
           InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
        fullWidth
            id="branch-name"
            label="Branch Name"
            variant="outlined"
            placeholder="Enter Branch Name"
            required
            margin="normal"
            value={data.BranchName}
            onChange={(e) => setData({...data, BranchName: e.target.value})}
          /> <br />
           <TextField
           InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
            asterisk: classes.requiredAsterisk,
          },
        }}
        fullWidth
        id="routing-number"
        label="Routing Number"
        variant="outlined"
        placeholder="Enter Routing Number"
        required
        type="number"
        margin="normal"
        value={data.RoutingNumber}
        onChange={handleRoutingInputChange}
          /> <FormHelperText error={error}>{routingHelperText}</FormHelperText><br />
          
            
            <div style={{marginBottom: "10px"}}>
                 {data?.cheque_leaf_file.length === 0 ? null: data?.cheque_leaf_file.name}
            </div>

          </Grid>
          </Grid>
    </div>
  // }

  if(data?.investorsdividendType === 'StockBonusShare' || data?.investorsdividendType === 'StockRightShare' ){
    dividendContent = <div>
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}> 
      
        <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
        fullWidth
            id="account-title"
            label="Account Title"
            variant="outlined"
            placeholder="Enter Account Title"
            required
            margin="normal"
            value={data.Actitle}
            onChange={(e) => setData({...data, Actitle: e.target.value})}
          /> <br />      
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
             fullWidth
            id="dividend-year"
            label="Dividend Year"
            variant="outlined"
            placeholder="Enter Your Dividend Year"
            required
            margin="normal"
            value={data.yearDividend}
            onChange={(e) => setData({...data, yearDividend: e.target.value})}
          /> <br />
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
             fullWidth
            id="no-of-share"
            label="No of Share"
            variant="outlined"
            placeholder="Enter No of Share"
            required
            margin="normal"
            value={data.nofshare}
            onChange={(e) => setData({...data, nofshare: e.target.value})}
          /> <br />
          </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}> 
        <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
        fullWidth
            id="bo-id"
            label="BO ID"
            variant="outlined"
            placeholder="Enter Your BO ID"
            required
            margin="normal"
            value={data.boID}
            onChange={(e) => setData({...data, boID: e.target.value})}
          /> <br />      
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
             fullWidth
            id="trec-holder"
            label="TREC Holder"
            variant="outlined"
            placeholder="Enter TREC Holder Name"
            // required
            margin="normal"
            value={data.trecHolderName}
            onChange={(e) => setData({...data, trecHolderName: e.target.value})}
          /> <br />
            <TextField
            InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
             fullWidth
            id="dp-id"
            label="DP ID"
            variant="outlined"
            placeholder="Enter your DP ID"
            // required
            margin="normal"
            value={data.dpID}
            onChange={(e) => setData({...data, dpID: e.target.value})}
          /> <br />
           
          <TextField
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="bo-acknowledge"
              label="CDBL Share Transfer File (JPG or PNG or JPEG or PDF)"
              variant="filled"
              placeholder="Attached Your CDBL Share Transfer File"
              fullWidth
              focused
              required={!data.cdbl_share_transfer_file}
              margin="normal"
              type="file"
              onChange={(e) =>{
                if(checkFileType(e.target.files[0])){
                  setData({...data, cdbl_share_transfer_file: e.target.files[0]})
                }else{
                  e.target.value = null;
                  setData({...data, cdbl_share_transfer_file: []});
                }
              } }
            />
            
            <div style={{marginBottom: "10px"}}>
                 {data.cdbl_share_transfer_file.length === 0 ? null: data.cdbl_share_transfer_file.name}
            </div>
          </Grid>
          </Grid>
    </div>
  }



  
    return (
      <>
       {dividendContent}
       <div > <br/></div>

             
      </>
    );
  };