import { Button } from '@material-ui/core';
import { AttachFileTwoTone, CancelRounded, DoneAll as DoneAllIcon, EditTwoTone, ListAltOutlined, ViewListOutlined } from "@material-ui/icons";
import SendIcon from '@material-ui/icons/Send';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";
import { getPermissionArray } from "../../../utils/strings/userString";
import FileUploadModal from './FileUploadModal';
import Table from './Table';

function 
FilterTableComponent() {
  const permissionArray = getPermissionArray();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    const [inwardDividendSummary, setInwardDividendSummary] = useState()
    const [rowsAffected, setRowsAffected] = useState()
    const history = useHistory();
    const { t } = useTranslation();
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');
  const [typeOfTheDividend, setTypeOfTheDividend] = useState('');


  const user = localStorage.getItem('user-name')
  const userFormatted = JSON.parse(user);
  const userType = userFormatted.userTypes[0]
  
    const controlModal = (e) => {
      console.log(e)
        setOpened((prevState) => !prevState);
        setTypeOfTheDividend(e.dividendType);
         setModalData(e);
    };

    const closeModal = () => {
        setOpened((prevState) => !prevState);
    };

    useEffect(() =>{
        axios.post(`inward-dividend/list`)
        .then((res) =>{
            setDataStore(res.data.payload)
            
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[inwardDividendSummary,rowsAffected])

    console.log(typeOfTheDividend)

    const paramsData = {
      page: currentPage,
      take: showPageSize,
  }
  //console.log(dataStore);

    // const deleteHandler = (id) => {
    //     Swal.fire({
    //       title: t('common.sweetAlert.sure'),
    //         text: t('common.sweetAlert.unDone'),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: t('common.sweetAlert.yesDelete'),
    //         cancelButtonText: t('common.sweetAlert.noCancel'),
    //         reverseButtons: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             axios.delete(`/inward-dividend/${id}`)
    //                 .then((res) => {
    //                   axios.post(`inward-dividend/list`,paramsData)
    //                   .then((res) =>{
    //                       setDataStore(res.data.payload)
    //                       if(res.data?.page){
    //                           setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
    //                       }
    //                   })
    //                   .catch((err) =>{
    //                   })
    //                 })
    //                 .catch((err) => {
    //                   alert("There was an error occured!")
    //                 });
    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //             Swal.fire(
    //               t('common.sweetAlert.cancelled'),
    //                 t('common.sweetAlert.safe'),
    //                 'error'
    //             );
    //         }
    //     });
    //   }
    const handleViewClick = (id) => {
        Swal.fire({
          title: t('common.sweetAlert.sure'),
          text: t('common.sweetAlert.viewConfirm'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('common.sweetAlert.yesView'),
          cancelButtonText: t('common.sweetAlert.noCancel'),
          //reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/app/inward-dividend/views/${id}`);
          }
        })
      };
    const handleInvestorsDetails = (id) => {
        Swal.fire({
          title: t('common.sweetAlert.sure'),
          text: t('common.sweetAlert.viewInvestorsDetails'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('common.sweetAlert.yesView'),
          cancelButtonText: t('common.sweetAlert.noCancel'),
          //reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/app/inward-dividend/investor-informations/${id}`);
          }
        })
      };

    const handleEditInwardDividend = (id) => {
        Swal.fire({
          title: t('common.sweetAlert.sure'),
          text: t('common.sweetAlert.editConfirm'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('common.sweetAlert.yesEdit'),
          cancelButtonText: t('common.sweetAlert.noCancel'),
          //reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/app/inward-dividend/edit/${id}`);
          }
        })
      };

      const inwardDivIssuerApproveHandler= (id) =>{
        console.log(id);
        Swal.fire({
          title: 'Are you sure to Submit this Dividend to CMSF? ',
            text: 'Please remember that this can not be undone. You will not be able to edit or upload investors list after submission',
            icon: 'warning',
          //  footer: 'you will not be able to edit or upload investors list after submission',
            showCancelButton: true,
            cancelButtonColor: "#DC143C", 
            confirmButtonColor: "#527853",    
            confirmButtonText: 'Yes, Sure',
            cancelButtonText: t('common.sweetAlert.noCancel'),
            //reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) 
            {
              axios.put(`inward-dividend/update-status-inward-dividend/${id}`,{status: 1})
              .then(res => {
                if(res.data.statusCode === 200){
                  setRowsAffected(res.data.payload.affected)
                // console.log(inwardDividendSummary)
                // console.log(res.data.payload.affected);
                if(res.data.payload.affected !==0)Swal.fire('Submitted to CMSF');
                else Swal.fire('Please check again');
              }
                else Swal.fire('Something went wrong');
              })
            .catch(err=>Swal.fire(err.response.data.errorMessages[0])
            )
          } 
          else if (result.dismiss === Swal.DismissReason.cancel) {
               Swal.fire(
                 'No changes have been made',  
               );
           }
       });
      };
      console.log(inwardDividendSummary)

      const approveHandler = (id) =>{
        Swal.fire({
          title: t('common.sweetAlert.sure'),
            text: 'Are you sure to Approve this Inward Dividend? remember that this can not be undone. ',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: "#DC143C", 
            confirmButtonColor: "#527853",    
            confirmButtonText: 'Yes, Sure',
            cancelButtonText: t('common.sweetAlert.noCancel'),
            //reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) 
            {
            axios.put(`inward-dividend/update-status-inward-dividend/${id}`,{status: 2})
              .then(res => {
                setInwardDividendSummary(res.data.payload)
                //if(res.data.payload.affected !== 0)Swal.fire('Dividend Approved')
                Swal.fire('Dividend Approved')
                //else Swal.fire("You can't approve at this moment", 'Issuer must approve it first');
              })
            .catch(err=>Swal.fire(err.response.data.errorMessages[0])
            )
          } 
               else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                      'No changes have been made',  
                    );
                }
            });
        }

        const declineHandler = (id) =>{
          Swal.fire({
            title: t('common.sweetAlert.sure'),
              text: 'Are you sure to Decline this Inward Dididend? remember that this can not be undone. ',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonColor: "#DC143C", 
              confirmButtonColor: "#527853",    
              confirmButtonText: 'Yes, Sure',
              cancelButtonText: t('common.sweetAlert.noCancel'),
              //reverseButtons: true
          }).then((result) => {
              if (result.isConfirmed) 
              {
                
              axios.put(`inward-dividend/update-status-inward-dividend/${id}`,{status: 4})
                .then(res => {
                  setInwardDividendSummary(res.data.payload)
                  Swal.fire(
                    'Dividend Declined',  
                  )
                })
              .catch(err=>Swal.fire(err.response.data.errorMessages[0])
              )
            } 
                 else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire(
                        'No changes have been made',  
                      );
                  }
              });
          }


    const columns = React.useMemo(
        () => [
            {
                Header: 'Inward Dividend Table',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },
                    {
                      Header: 'Date',
                      accessor: 'date',
                      Cell: ({ value }) => {
                          const date = new Date(value);
                          return <>{date.toLocaleDateString("en-GB")}</>;
                        },
                  },
                    {
                        Header: 'Issuer',
                        accessor: 'issuer.name'
                    },
                    {
                      Header: 'Dividend Type',
                      accessor: 'dividendType'
                    },
                    
                    {
                      Header: 'Dividend Year',
                      accessor: 'year'
                    },
                     
                    {
                        Header: 'Total Cash',
                        accessor: 'total_cash'
                    },
                    {
                        Header: 'Total FC',
                        accessor: 'total_fc'
                    },
                    {
                        Header: 'Total Stock',
                        accessor: 'total_stock'
                    },
                    {
                        Header: 'Remarks',
                        accessor: 'remarks'
                    },{
                      Header: 'Current Status',
                      accessor: 'status',
                      Cell: ({row}) => 
                      <>
                        {row.values.status === 0 ? (
                               'Initiated'
                             ) : row.values.status === 1 ? 
                             (
                                `Approved by Issuer`

                             ) : row.values.status === 2 ? (
                                `Approved by CMSF`

                             ) : row.values.status === 4 ? (
                                 `Cancelled by CMSF`

                            ) :  (
                                <> </>
                            )}
                      </>
                        },
                           
                    {
                        Header: 'Actions',
                        accessor: 'issuerid',
                        Cell: ({row}) => <>
                        {/* the first button (enlarged view of the entry) comes default with the list */}
                         <Button
                         style={{margin: '2px',minWidth:"30px", maxWidth:"30px", height:"30px"}} size="small" title='View' variant="contained" color="primary" 
                         onClick={e=>handleViewClick(row.original.id)}
                        > <ViewListOutlined /></Button>
                        
                        <Button
                        disabled={!permissionArray.includes("Inward-Dividend-list-sub-investor-details")}
                         style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Investors details' variant="contained" color="primary" 
                         onClick={e=>handleInvestorsDetails(row.original.id)}
                        ><ListAltOutlined /> </Button>


                        <Button 
                        disabled={!permissionArray.includes("Inward-Dividend-list-sub-file-upload")}
                        style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" size="small" title='File Upload' variant="contained"  
                        onClick={()=>controlModal(row.original)}
                        disabled={row.original.status===1 ||row.original.status===2||row.original.status===4}
                         >
                        
                        <AttachFileTwoTone />
                        </Button>

                        

                        {(userType === 'Super_Admin' || userType === 'Admin_CMSF' )&& 
                        <>
                          <Button 
                          // disabled={!permissionArray.includes("Inward-Dividend-list-sub-file-upload")}
                            style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} color="primary" size="small" title='Approve Dividend' variant="contained"  
                            onClick={()=>approveHandler((row.original.id))} 
                            disabled={row.original.status===2 || row.original.status===4}
                            >
                            
                            <DoneAllIcon />
                          </Button>

                          <Button
                            disabled={!permissionArray.includes("Inward-Dividend-list-sub-delete")}
                            style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Decline Dividend' variant="contained" color="secondary" 
                            onClick={()=>declineHandler(row.original.id)}
                            disabled={row.original.status===2 || row.original.status===4}>
                            <CancelRounded  /> 
                        </Button>
                        </>
                        }

                        {userType === 'Issuer' && 
                        <>
                          <Button
                          disabled={!permissionArray.includes("Inward-Dividend-list-sub-edit")||(row.original.status===1 ||row.original.status===2 ||row.original.status===4)}
                          style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='Edit' variant="contained" color="primary" 
                          onClick={e=>handleEditInwardDividend(row.original.id)}
                          
                          ><EditTwoTone  /> 
                        </Button>
                        {/* Hello Bangladesh 2*/}
                        <Button
                            disabled={!permissionArray.includes("Inward-Dividend-list-sub-delete")}
                            style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px',backgroundColor: "#7A9D54"}} size="small" title='Submit to CMSF' variant="contained" color="secondary" 
                            onClick={()=>inwardDivIssuerApproveHandler(row.original.id)}
                            disabled={(row.original.status===1 || row.original.status===2|| row.original.status===4)}>
                            <SendIcon />
                          </Button>
                          </>
                          }
                        
                        </>
                    },
                ],
            },
        ],
        []
    )

    return (
        <div>
            <div>
                <Table 
                showPageSize={showPageSize} 
                setShowPageSize={setShowPageSize} 
                setNumberOfpage={setNumberOfpage} 
                numberOfPage={numberOfPage} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage} 
                setDataStore={setDataStore} 
                columns={columns} 
                data={dataStore} 
                />
           </div>
                <FileUploadModal open={opened} data={modalData} control={controlModal} typeOfTheDividend={typeOfTheDividend} closeModal={closeModal}/>               
                </div> 
    )
}

export default FilterTableComponent;