import { Button } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import axios from "../../../utils/axios";
import { getPermissionArray } from "../../../utils/strings/userString";
import FilterTableComponent from "./FilterTableComponent";


export default function ViewInwordDividendComponent(){
 const permissionArray = getPermissionArray();
  const { t } = useTranslation();
    const [isLoading, setIsLoding] =useState(true)
    const [isSuccess, setIsSuccess] =useState(false)
    const [data, setData] = useState("")
    const [apiData, setApiData] = useState([])
   

    const params = useParams();
   
console.log(params.id)
    useEffect(() => {

        axios.get(`inward-dividend/${params.id}`)
        .then((res) =>{
            if(res.data.success){
                setIsSuccess(true)
                setIsLoding(false)
                setData(res.data.payload)
            }
        })
        .catch((err) =>{
            alert("There was an error occured!")
        })
        // axios.get(`/bank-bo/list`)
        // .then((res) =>{
        //     if(res.data.success){
        //       setApiData(res.data.payload)
        //     }
        // })
        // .catch((err) =>{
        //     alert("There was an error occured!")
        // })
      }, [params.id]);

      const pdfDownloadHandler = (id) => {
        axios({
          url: `inward-dividend/application/${params.id}`, //your url
          method: 'GET',
          responseType: 'blob', // important
      })
      .then((response) =>{
        //setLoader(false)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `dividend_details_${params.id}.PDF`);
        document.body.appendChild(link);
        link.click();
        //window.open(url);
        //link.remove();
      })
      .catch((error) =>{
        //setLoader(false);
        //setError(error.message);
        alert("There was an error ocurred!")
      })
  };
      
console.log(apiData);
console.log(apiData);


      let content = "";
      content = <div style={{display: 'flex',flexDirection: 'row'}}> 
      
        <Paper  style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '40%',borderRadius: 20, borderColor: 'black'}}>
            <Table size='small'>
              <TableHead>
              <TableRow>
                  <TableCell style={{dispaly: "flex" , fontSize:"20px", backgroundColor:"#96B6C5",}}></TableCell>
                  <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"20px", backgroundColor:"#96B6C5",}}>Basic Information</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                <TableRow>
                  <TableCell>ID:</TableCell>
                  <TableCell>{data.autoId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Uploaded on</TableCell>
                  <TableCell>{data.date}</TableCell>
                </TableRow>
            
                <TableRow>
                  <TableCell>Dividend Type</TableCell>
                  <TableCell>{data.dividendType}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Dividend Year</TableCell>
                  <TableCell>{data.year}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>REF</TableCell>
                  <TableCell>{data.ref}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Remarks</TableCell>
                  <TableCell>{data.remarks}</TableCell>
                </TableRow>
            

              </TableBody>
            </Table>
        </Paper>

          <Paper style={{display: 'flex',flexDirection: 'row',margin: '7px',width: '48%',borderRadius: 20}}>
            <Table size='small'>
              <TableHead>
              </TableHead>
              <TableBody>
          <TableRow>
                  <TableCell style={{dispaly: "flex" , fontSize:"18px", backgroundColor:"#96B6C5",}}></TableCell>
                  <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"18px", backgroundColor:"#96B6C5",}}>Cash Dividend (BDT)</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Total Cash</TableCell>
                  <TableCell>{data.total_cash}</TableCell>
                </TableRow>

                {/* cash_9_1 */}
                <TableRow>
                  <TableCell>Cash Dividend</TableCell> 
                  <TableCell>{data.cash_9_1}</TableCell>
                </TableRow>

                {/* cash_9_4 */}
                <TableRow>
                  <TableCell>IPO</TableCell>
                  <TableCell>{data.cash_9_4}</TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell>Total Foreign Currency</TableCell>
                  <TableCell>{data.total_fc}</TableCell>
                </TableRow> */}

      <TableRow>
                  <TableCell style={{dispaly: "flex" , fontSize:"18px", backgroundColor:"#96B6C5",}}></TableCell>
                  <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"18px", backgroundColor:"#96B6C5",}}>FC Dividend (USD)</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Total Cash</TableCell>
                  <TableCell>{data.total_cash}</TableCell>
                </TableRow>

                {/* cash_9_1 */}
                <TableRow>
                  <TableCell>Cash Dividend</TableCell> 
                  <TableCell>{data.cash_9_1}</TableCell>
                </TableRow>

                {/* cash_9_4 */}
                <TableRow>
                  <TableCell>IPO</TableCell>
                  <TableCell>{data.cash_9_4}</TableCell>
                </TableRow>

                {/* fc_9_1
                <TableRow>
                  <TableCell>IPO</TableCell>
                  <TableCell>{data.fc_9_4}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell style={{dispaly: "flex" , fontSize:"20px", backgroundColor:"#96B6C5",}}></TableCell>
                  <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"18px", backgroundColor:"#96B6C5",}}>Stock Dividend</TableCell>
                </TableRow>

                {/* stock_9_2 + stock_9_3  */}
                <TableRow>
                  <TableCell>Total Share</TableCell>
                  <TableCell>{(data.stock_9_2)+(data.stock_9_3)}</TableCell>
                </TableRow>

                {/* stock_9_2 */}
                <TableRow>
                  <TableCell>Bonus Share</TableCell>
                  <TableCell>{data.stock_9_2}</TableCell>
                </TableRow>

                {/* stock_9_3 */}
                <TableRow>
                  <TableCell>Right Share</TableCell>
                  <TableCell>{data.stock_9_3}</TableCell>
                </TableRow>
                </TableBody>
            </Table>
          </Paper>


    <h1 style={{marginBottom: "50px"}}> </h1></div>;



let content2 = "";
content2 = <div> <Paper>
                  <Table>
                    <TableHead>
                      <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"24px", backgroundColor:"#9DC08B",}}></TableCell>
                      <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"24px", backgroundColor:"#9DC08B",}}></TableCell>
                      <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"24px", backgroundColor:"#9DC08B",}}>Details of Uploaded Excel Files</TableCell>
                      <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"24px", backgroundColor:"#9DC08B",}}>{apiData.companyName}</TableCell>
                      <TableCell style={{dispaly: "flex" ,textAlign: "leftalign", fontSize:"24px", backgroundColor:"#9DC08B",}}></TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell>Dividend Type</TableCell>
                          
                            <TableCell>Date of Upload</TableCell>
                            <TableCell>Download</TableCell>        
                        </TableRow>
                        <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>demo_investor_file_1</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>21-05-2023</TableCell>
                            <TableCell>
                              <Button style={{backgroundColor:"#A4BE7B"}}>
                                hi
                              </Button>
                            </TableCell>
                        </TableRow>  
                        <TableRow>
                            <TableCell>2</TableCell>
                            <TableCell>demo_investor_file_2</TableCell>
                            <TableCell>stock</TableCell>
                            
                            <TableCell>21-05-2023</TableCell>
                            <TableCell>
                              <Button style={{backgroundColor:"#A4BE7B"}}>
                              hi
                              </Button>
                            </TableCell>
                        </TableRow>    
                    </TableBody>
                  </Table>
                </Paper>

<h1 style={{marginBottom: "50px"}}> </h1></div>;


    return(
        <div>
          <PageTitle title={ t('inwardDividend.view.pageTitle')} button={
          <Link to="/app/inward-dividend/list"> 
            <Button
              variant="contained"
              size="medium"
              color="primary">
                  { t('inwardDividend.view.listLink')}
            </Button>
          </Link>}  />

            {isSuccess && content}
            {/* {isSuccess && content2} */}
          <Button 
              // disabled={!permissionArray.includes("Inward-Dividend-list-sub-file-upload")}
              style={{margin: "25px"}}
              variant="contained"
              size="medium"
              color="primary"
              onClick={pdfDownloadHandler}>
                Downlod Copy
          </Button>
            <FilterTableComponent/>
            
        </div>
    )
}