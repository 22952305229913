import { Button } from '@material-ui/core';
import { DeleteForeverTwoTone, EditTwoTone, ViewListOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from "../../../utils/axios";
import { getPermissionArray } from "../../../utils/strings/userString";
import ListModal from './ListModal';
import Table from './Table';

function FilterTableComponent() {
  const permissionArray = getPermissionArray();
    const params = useParams();
    const [showPageSize, setShowPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberOfPage, setNumberOfpage] = useState(1)
    const [dataStore, setDataStore] = useState([]);
    
  const [opened, setOpened] = useState(false);
  const [modalData, setModalData] = useState('');

  const [editOpened, setEditOpened] = useState(false);
  const [editModalData, setEditModalData] = useState('');

  const [addOpened, setAddOpened] = useState(false);
  const [addModalData, setAddModalData] = useState('');


  
  const { t } = useTranslation();
  const history = useHistory()

    const controlModal = (e) => {
        setOpened((prevState) => !prevState);
        setModalData(e);
      
    };

    const controlEditModal = (e) => {
        setEditOpened((prevState) => !prevState);
        setEditModalData(e);
        
        console.log(e);
    };

    const controlAddModal = (e) => {
        setAddOpened((prevState) => !prevState);
        setAddModalData(e);
        console.log(e);
    };
    const handleEditIssuer = (id) => {
        Swal.fire({
          title: t('common.sweetAlert.sure'),
          text: t('common.sweetAlert.editConfirm'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('common.sweetAlert.yesEdit'),
          cancelButtonText: t('common.sweetAlert.noCancel'),
          //reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/app/issuer/edit/${id}`);
          }
        })
      };

    useEffect(() =>{
        axios.get(`issuer`)
        .then((res) =>{
            setDataStore(res.data.payload)
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    },[])

    const paramsData = {
      page: currentPage,
      take: showPageSize,
  }

    const confirmHandler = (idd) => {
        Swal.fire({
            title: t('common.sweetAlert.sure'),
            text: t('common.sweetAlert.unDone'),
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('common.sweetAlert.yesDelete'),
            cancelButtonText: t('common.sweetAlert.noCancel')
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/issuer/${idd}`,{
                }).then((res)=>{
                      //setDeleteOpened((prevState) => !prevState);
                          getData();
                                });
                console.log(`${idd}  deleted successfully`)
              Swal.fire(
                'Deleted!',
                'Selected Issuer has been deleted.',
                'success'
              )
            }
          })
    }

    const getData = () =>{
        axios.get(`issuer?take=${showPageSize}&page=${currentPage}`)
        .then((res) =>{
            setDataStore(res.data.payload)
            console.log('use');
            setCurrentPage(1);
            if(res.data?.page){
                setNumberOfpage(Math.ceil(res.data?.total/showPageSize))
            }
        })
        .catch((err) =>{
        })
    }

    // const getDataSync = (page,take, remarksValue,dateValue, issuerValue, totalCashValue, totalFcValue, totalStockValue) =>{
    //     axios.get(`issuer?take=${showPageSize}&page=${currentPage}`)
    //     .then((res) =>{
    //         setDataStore(res.data.payload)
    //         setCurrentPage(page);
    //         setShowPageSize(take)
    //         console.log('after');
    //     })
    //     .catch((err) =>{
    //         alert("There was a error occured!")
    //     })
    // }
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Particular',
                columns: [
                    {
                        Header: 'ID',
                        accessor: 'autoId',
                    },{
                        Header: 'Trade Code',
                        accessor: 'tradecode',
                    },
                    {
                        Header: 'Issuer Name',
                        accessor: 'name'
                    },
                    {
                        Header: 'Sector',
                      accessor: 'sector'
                    },
                    ,
                    {
                        Header: 'Category',
                        accessor: 'category'
                    },
                    {
                      Header: 'Received Amount',
                      accessor: ''
                    },
                    {
                      Header: 'Settled Amount',
                    accessor: ''
                  },
                    // {
                    //     Header: 'Debut Date',
                    //     accessor: 'debutdate',
                    //     Cell: ({ value }) => {
                    //         const date = new Date(value);
                    //         return <>{date.toLocaleDateString("en-GB")}</>;
                    //       },
                    // },
                    
                    {
                        Header: 'Actions',
                        //accessor: 'title',
                      Cell: ({row}) =>
                      
                      <span>
                        {/* <Link style={{margin: '2px'}} to={`/app/issuer/list/${row.original.id}`}> <Button
                         style={{margin: '2px', maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" title='View' variant="contained" color="primary" 
                        >
                                <ViewListOutlined />
                            </Button></Link> */}

                    <Link style={{margin: '2px'}} to={`/app/issuer/list/${row.original.id}`}>
                        <Button 
                            style={{ margin: '5px', backgroundColor: 'black'}}  
                            variant="contained" 
                            color="primary" 
                            >
                       
                            <ViewListOutlined />                       
                        </Button>
                    </Link>
                     
                    {/* <Link style={{margin: '2px'}} to={`/app/issuer/edit/${row.original.id}`}> */}
                      <Button
                      disabled={!permissionArray.includes("Issuer-list-sub-edit")}
                      style={{ margin: '5px', backgroundColor: 'blue'}}  
                      variant="contained" 
                      color="primary" 
                      onClick={()=>handleEditIssuer(row.original.id)}
                      >
                       
                      <EditTwoTone  />                        
                      </Button>
                    {/* </Link> */}
                            
                      <Button 
                     
                      style={{ margin: '5px', backgroundColor: 'red'}}  
                      disabled={!permissionArray.includes("Issuer-list-sub-delete")}
                      variant="contained" 
                      color="primary" 
                      onClick={()=>confirmHandler(row.original.id)}
                      >
                       
                      <DeleteForeverTwoTone />                        
                      </Button>


                     
                     
                     </span>
                     
                    },
                   
                    
                ],
            },
           
        ],
        []
    )

    return (
        <>
        {/* <h1>{t('Issuer.sideBarListTitle')}</h1> */}
    {/* <Button style={{ marginTop: '10px', marginBottom: '10px' , marginRight: '10px', marginLeft: '10px'}} fullwidth ='true'  color="primary" variant="contained" onClick={()=>controlAddModal()}> { t('Issuer.newCompanyAdd')} </Button> */}
    
        <div>
            <Table 
           // getDataSync={getDataSync} 
            showPageSize={showPageSize} 
            setShowPageSize={setShowPageSize} 
            setNumberOfpage={setNumberOfpage} 
            numberOfPage={numberOfPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} 
            setDataStore={setDataStore} 
            columns={columns} 
            data={dataStore} 
            params={params}
            />
           {/* < AreaChart /> */}

        </div>

        {/* <EditModal open={editOpened} data={editModalData} control={controlEditModal} getUserData={getData}/>  */}
        <ListModal open={addOpened} data={addModalData} control={controlAddModal} getUserData={getData} /> 
        {/* <EditModal open={editOpened} data={editModalData} control={controlEditModal} getUserData={getData} /> */}
    </>
    )
}

export default FilterTableComponent;