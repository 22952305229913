import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "../../../utils/axios";
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'right',
  },


  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
}));

export default function Assign({ open,data, control}) {

      const classes = useStyles();
      const { t } = useTranslation();
      var disableId = 10
      const {id} = data;
      const userId = id;
      
      
      const [data2,setData2] = useState([]);
      
      const assignHandler = (idd) => {
       
        
        const values = {
          "roles":
            [idd]
          
        };
       
       
        axios.post(`/roles/user-roles/${id}`,values     
        ).then((res)=>{
          
         
      
        }).catch((err)=>{
            console.log(err);
        });
        ; 
        Swal.fire({
          position: 'bottom-end',
          icon: 'success',
          title: 'Assigned suscessfully',
          showConfirmButton: false,
          timer: 1500
        })
      };


    const getData2 = () =>{
      if (id){
      axios.get(`roles?take=0`)
      .then((res) =>{
          setData2(res.data.payload)
          
      })
      .catch((err) =>{
      })
    }
  }


    useEffect(()=>{
      getData2();
    },[userId]);
    
    

    const columns = [
          {
            name: "title",
            label: "Roles",
            options: {
            filter: true,
            sort: true,
            }
          },
          
         

          {
            name: "id",
            label: "Action",
            options: {
         
                  customBodyRender: (value, tableMeta, updateValue) => {
                   
                    return (
                      <div className={classes.root}>
                        
                      <Button style={{margin: '5px'}}  size="small"  variant="contained" color='primary' 
                     // disabled={tableMeta.rowIndex} 
                      onClick={()=> assignHandler(value)
                      }>
                     
                      Assign
                      </Button>
                      </div>
                    )
                  }
            }
          }  
      ]
  
     
  
      const options = {
        selectableRows: 'none',
        enableNestedDataAccess: '.',
      };

 
  return (
    <div>
        
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={control}
          disableEscapeKeyDown
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
          timeout: 500,
          }}
        >
      
        <Fade in={open}>
          <div className={classes.paper}>
            <MUIDataTable
                  //title={"Permissions"}
                  data={data2}
                  columns = {columns}
                  options={options}
                  highlightOnHover={true}
                  fixedHeader
                  paginate={false}
                  pagination={false}
                  //rowsPerPage ={5} 
                  //isRowSelectable
                  caseSensitive={false} 
                  print={true}       
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
