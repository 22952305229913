import { FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import ReduceContext from "./ReduceContext";
import useStyles from "./useStyle";

export const ApplicationInfo = (props) => {
  const classes = useStyles();

  const {data, setData} = useContext(ReduceContext);
  let currentDate = new Date().toJSON().slice(0, 10);

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [bohelperText, seBotHelperText] = useState('');
  const [phoneHelperText, setPhoneHelperText] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setData({...data, nid: value})
    const isValidLength = value.length === 10 || value.length === 13 || value.length === 17;

    setError(!isValidLength);
    setHelperText(isValidLength ? '' : 'NID length must be 10, 13, or 17 digits');
  };

  const handleBOInputChange = (event) => {
    const value = event.target.value;
    setData({...data, boID: value})
    const isBoLengthValid = value.length === 16;

    setError(!isBoLengthValid);
    seBotHelperText(isBoLengthValid ? '' : 'BO ID length must be 16-digits');
  };

  const handlePhoneInputChange = (event) => {
    const value = event.target.value;
    setData({...data, phoneNumber: value})
    const isPhoneLengthValid = value.length === 11;

    setError(!isPhoneLengthValid);
    setPhoneHelperText(isPhoneLengthValid ? '' : 'Phone Number must be 11-digits');
  };
 
    return (
      <>

        <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Claimant Type</InputLabel>
          
          <Select
          required
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data.claimType}
          onChange={(e) => setData({...data, claimType: e.target.value})}
          >
            <MenuItem value={"Self"}>Self</MenuItem>
            <MenuItem value={"Authorized"}>Authorized Person</MenuItem>
            <MenuItem value={"PowerOfAttorney"}>Power of Attorney</MenuItem>
            <MenuItem value={"Successor"}>Successor/Nominee (of deceased person)</MenuItem>
          </Select>
        </FormControl>
        <h4 style={{marginTop:"15px"}}> </h4>

        <TextField
        InputProps={{}}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="full-name"
              label="Full Name"
              variant="outlined"
              placeholder="Enter Your Full Name"
              fullWidth
              required
              value={data.fullName}
              margin="normal"
              onChange={(e) => setData({...data, fullName: e.target.value})}
            />
             <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="folio-number"
              label="Folio Number"
              variant="outlined"
              placeholder="Enter Your Folio Number"
              type="number" 
             // required             
              fullWidth
              margin="normal"
              value={data.folioNumber}
              onChange={(e) => setData({...data, folioNumber: e.target.value})}
            />




       
  <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="boid"
              label="BO ID"
              variant="outlined"
              placeholder="Enter Your BO ID"
              fullWidth
              type="number"
              //required
              value={data.boID}
              margin="normal"
             // onChange={(e) => setData({...data, boID: e.target.value})}
             onChange={handleBOInputChange}
            /><FormHelperText error={error}>{bohelperText}</FormHelperText>
             
         <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="share-holding"
              label="Share Holding"
              variant="outlined"
              placeholder="Enter Your Share Holding"
              type="number" 
              required             
              fullWidth
              margin="normal"
              value={data.shareholding}
              onChange={(e) => setData({...data, shareholding: e.target.value})}
            />
      
        <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="warrant-number"
              label="Warrant Number"
              variant="outlined"
              placeholder="Enter Your Warrant Number"
              fullWidth
              type="number"
              margin="normal"
              // required
              value={data.warrantNumber}
              onChange={(e) => setData({...data, warrantNumber: e.target.value})}
            />
                      </Grid>
                      
         <Grid item lg={6} md={6} sm={12} xs={12}>

               <FormControl fullWidth>
           <InputLabel id="demo-simple-select-label2">Dividend Type </InputLabel>
                   <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select"
                        value={data.investorsdividendType}
                        onChange={(e) => setData({...data, investorsdividendType: e.target.value})}
                       
                      >
                        <MenuItem value={"CashDividend"}>Cash Dividend</MenuItem>
                        <MenuItem value={"PublicSubscriptionMoney"}>Public Subscription Money</MenuItem>
                        <MenuItem value={"FCCashDividend"}>FC Cash Dividend</MenuItem>
                        <MenuItem value={"FCPublicSubscriptionMoney"}>FC Public Subscription Money</MenuItem>
                        <MenuItem value={"StockBonusShare"}>Stock Bonus Share</MenuItem>
                        <MenuItem value={"StockRightShare"}>Stock Right Share</MenuItem>
                      </Select>
                    </FormControl>

                    <h4 style={{marginTop:"15px"}}> </h4>
                    <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="father-name"
              type="text"
              label="Father's Name"
              variant="outlined"
              placeholder="Enter Your Father's Name"
              fullWidth
              required
              value={data.fathersName}
              margin="normal"
              onChange={(e) => setData({...data, fathersName: e.target.value})}
            />

<TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="mother-name"
              label="Mother's Name"
              variant="outlined"
              placeholder="Enter Your Mother's Name"
              fullWidth
              required
              value={data.mothersName}
              margin="normal"
              onChange={(e) => setData({...data, mothersName: e.target.value})}
            />

<TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="phone-number"
              label="Phone Number"
              variant="outlined"
              placeholder="Enter Your Phone Number"
              type="number" 
              required             
              fullWidth
              margin="normal"
              value={data.phoneNumber}
              onChange={handlePhoneInputChange}
            /><FormHelperText error={error}>{phoneHelperText}</FormHelperText>
            

        <TextField {...props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.root,
          },
        }}
              id="nid"
              label="National Id"
              variant="outlined"
              placeholder="Enter Your National Id"
              required
              type="number"
              fullWidth
              margin="normal"
              value={data.nid}
              // onChange={(e) => setData({...data, nid: e.target.value})}
              //error={error}
        onChange={handleInputChange}
            /><FormHelperText error={error}>{helperText}</FormHelperText>

<TextField
InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}
InputLabelProps={{
  classes: {
    outlined: classes.root,
  },
}}
              id="date-of-birth"
              label="Date Of Birth"
              type="date"
              inputProps={{
                max: `${currentDate}`
              }}

              variant="outlined"
              focused
              required
              // placeholder="Enter DateOfBirth"
              fullWidth
              margin="normal"
              value={data.dob}
              onChange={(e) => setData({...data, dob: e.target.value})}
            />
       
                </Grid>

           <Grid item lg={12} md={12} sm={12} xs={12}>

          <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              outlined: classes.root,
            },
          }}
              id="address"
              label="Address"
              variant="outlined"
              
              placeholder="Enter Your Address"
              type="text" 
              required
              fullWidth
              margin="normal"
              value={data.address}
              onChange={(e) => setData({...data, address: e.target.value})}
            />
                </Grid>            
          </Grid>
    <h2> </h2>

      </>
    );
  };

  